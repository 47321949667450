import { ChangeEvent, useCallback, useState } from 'react';
import { Box, Button, Card, CardContent, CardHeader, Divider, TextField } from '@mui/material';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';

export const SettingsPassword = (props: any) => {
    const [resetSent, setResetSent] = useState(false)
    const auth = getAuth();

    const handleSubmit = useCallback(async (e: any) => {
        const email = auth.currentUser?.email ?? ""
        sendPasswordResetEmail(auth, email)
            .then(() => { setResetSent(true) })
            .catch((e) => { alert(e); });
    }, [])

    return (
        <form {...props}>
            <Card>
                <CardHeader
                    subheader="Manage authentication"
                    title="Authentication"
                />
                <Divider />
                <CardContent>
                    {!resetSent && <Button onClick={handleSubmit}>Reset Password</Button>}
                    {resetSent && <Button style={{ color: "red" }} onClick={() => { setResetSent(false) }}>Email sent</Button>}
                </CardContent>
                <Divider />
            </Card>
        </form>
    );
};