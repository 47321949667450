import { useEffect, useRef, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {
    Avatar,
    Box,
    Breadcrumbs,
    Card,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    Typography,
    Button,
    IconButton,
    Tooltip,
    TableContainer
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Link from '@mui/material/Link';
import { styled } from "@mui/material/styles";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Upload } from '@mui/icons-material';
import CreateNewFolderRoundedIcon from '@mui/icons-material/CreateNewFolderRounded';
// import { StorageReference, getMetadata, FullMetadata } from 'firebase/storage';
import { deleteFile, getDownloadFromItems, getOrganizationStorageFiles } from '../../../actions/storage';

import { DataManagerBreadcrumbs } from './data-manager-files-breadcrumbs';
// Raw file handler
import { getStorage, ref, listAll, StorageReference, getDownloadURL, getMetadata, FullMetadata, deleteObject, uploadBytes, uploadString } from "firebase/storage";
import { getUserOrg } from "../../../actions/organization";
import { app } from "../../../firebase-config";
import FolderRowComponent from './data-manager-files-folder-row';

import { regionToBucketMapping } from '../../../constants/regions';
import FileRowComponent from './data-manager-files-file-row';

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { createFolder } from '../../../actions/folder';


interface DataManagerFilesInterface {
    isDataManager: boolean,
    filePasser?: Function,
    refresh?: boolean,
    lockFolder?: string,
    setCurrentFolder?: Function,
    setOpenUploadModal?: Function,
    isIncidentManager: boolean
}

export interface FileData {
    id: string,
    name: string,

    isDir?: boolean,
    size?: number,
    contentType?: string,
    modDate?: string;

    [property: string]: any;
}
type ItemTuple = [StorageReference, FileData]

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    [`&.${tableRowClasses.root}`]: {
        height: "20px",
        maxHeight: "20px",
        minHeight: "20px"
    },
}))


export const DataManagerFiles = (props: DataManagerFilesInterface) => {
    const [selectedFileIds, setSelectedFileIds] = useState<string[]>([]);
    const [limit, setLimit] = useState(4); //TODO: set this dynamically based off size of window and row?
    const [page, setPage] = useState(0);
    const [pageOptions, setPageOptions] = useState<number[]>([])
    const [items, setItems] = useState<ItemTuple[]>([])
    const [folderPrefix, setFolderPrefix] = useState<string>(props.lockFolder || '/')

    const [folderDeleteRef, setFolderDeleteRef] = useState<StorageReference>()
    const [folderDeletePromt, setFolderDeletePromt] = useState(false)
    const [openFolderPromt, setFolderPromtOpen] = useState(false);
    const [folderName, setFolderName] = useState('')

    const rowHeightRef = useRef(null)
    const tableHeightRef = useRef(null)

    // can be glitchy if you spam wiggle the page
    const handleResize = () => {
        if (rowHeightRef.current && tableHeightRef.current) {
            //@ts-expect-error
            const limit = Math.floor(tableHeightRef.current.clientHeight / rowHeightRef.current.clientHeight) - 2
            setLimit(limit)
            setPageOptions([limit])
        }
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize, false);
        handleResize()
        setTimeout(handleResize, 1 * 1000)
    }, [items])

    // const handleLimitChange = (event: any) => {
    //     setLimit(event.target.value);
    //     setPage(0)
    // };

    const handlePageChange = (event: any, newPage: number) => {
        setPage(newPage);
    };

    const handleDirChange = (path: string) => {
        const to = `/${path}`
        setFolderPrefix(to)
        if (props.setCurrentFolder) {
            props.setCurrentFolder(to)
        }
    }

    const handleSelectAll = (event: any) => {
        let newSelectedFileIds: string[];

        if (event.target.checked) {
            newSelectedFileIds = items.map(([item, metadata]) => item.name);
        } else {
            newSelectedFileIds = [];
        }

        setSelectedFileIds(newSelectedFileIds);
        if (props.filePasser) {
            if (event.target.checked) {
                props.filePasser(items.filter(item => item[1].contentType != "folder"))
            }
            else {
                props.filePasser([])
            }
        }
    };

    const handleSelectOne = (event: any, id: string) => {
        const selectedIndex = selectedFileIds.indexOf(id);
        let newSelectedFileIds: string[] = [];

        if (selectedIndex === -1) {
            newSelectedFileIds = newSelectedFileIds.concat(selectedFileIds, id);
        } else if (selectedIndex === 0) {
            newSelectedFileIds = newSelectedFileIds.concat(selectedFileIds.slice(1));
        } else if (selectedIndex === selectedFileIds.length - 1) {
            newSelectedFileIds = newSelectedFileIds.concat(selectedFileIds.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedFileIds = newSelectedFileIds.concat(
                selectedFileIds.slice(0, selectedIndex),
                selectedFileIds.slice(selectedIndex + 1)
            );
        }

        setSelectedFileIds(newSelectedFileIds);
        if (props.filePasser) {
            const itemsToPass = items.filter(([item, metadata]) => newSelectedFileIds.includes(item.name))
            props.filePasser(itemsToPass)
        }
    };

    const downloadItems = async (items: StorageReference[]) => {
        getDownloadFromItems({ items: items }).then(() => { }).catch((e) => { console.log(e) })
    }

    const deleteItem = async (item: StorageReference) => {
        await deleteFile(item)
        console.log('deleted item')
        loadFiles()
    }

    const loadFiles = async () => {

        // Get Firebase storage info
        const org = await getUserOrg()
        if (org === undefined) {
            return undefined
        }
        const uoid: string = org.uoid
        const orgRegion: string = org.region
        const bucketURL = Object(regionToBucketMapping)[orgRegion] // TODO: what if this returns null?
        const pathURL = uoid + '/core' + (folderPrefix !== '/' ? folderPrefix : '')
        const storage = getStorage(app, bucketURL)
        const listRef = ref(storage, pathURL)

        console.log('Retrieving files for folder:', pathURL)

        // List and convert items for path
        const firebaseFiles: ItemTuple[] = [];
        await listAll(listRef)
            .then(async (res) => {

                // process files
                await Promise.all(res.items.map(async (item: StorageReference) => {
                    if (item.name !== '.FS_Store') {
                        const metadata = await getMetadata(item)
                        const data: FileData = {
                            id: item.fullPath.split(uoid + '/core/').join(''),
                            name: item.name,
                            size: metadata.size,
                            modDate: new Date(metadata.timeCreated).toUTCString(),
                            contentType: metadata.contentType,
                        }
                        firebaseFiles.push([item, data])
                    }
                }))

                // process folders
                const folderNames: any[] = []
                await Promise.all(res.prefixes.map(async (prefix: StorageReference) => {
                    const data: FileData = {
                        id: prefix.fullPath.split(uoid + '/core/').join(''),
                        name: prefix.name,
                        isDir: true,
                        contentType: 'folder',
                    }
                    firebaseFiles.push([prefix, data])
                    folderNames.push(prefix.name)
                }))
                console.log("folders:", folderNames)

            }).catch((error) => {
                console.log(error)
                // Uh-oh, an error occurred!
            })

        // sort
        firebaseFiles.sort((a, b) => (a[1].id > b[1].id) ? 1 : ((b[1].id > a[1].id) ? -1 : 0))
        setItems(firebaseFiles)
    }

    // Folder Creation
    const createFolderHandler = async () => {

        createFolder(folderPrefix, folderName).then((newPrefix) => {
            setFolderPrefix(newPrefix)
            setFolderName('')
            setFolderPromtOpen(false)
            if (props.setCurrentFolder) {
                props.setCurrentFolder(newPrefix)
            }

        }).catch((err) => {
            alert(err)
            setFolderName('')
            setFolderPromtOpen(false)
        })
    }

    const handleOpenFolderPromt = () => {
        setFolderPromtOpen(true);
    };

    const handleCloseFolderPromt = () => {
        setFolderPromtOpen(false);
    };

    // Folder Deletion
    const getChildRefs = async (storageRef: StorageReference): Promise<StorageReference[]> => {
        // recursive function to get all child files
        const childRefs: StorageReference[] = []
        await listAll(storageRef).then(async (res) => {
            childRefs.push(...res.items)
            await Promise.all(res.prefixes.map(async (prefixRef) => {
                childRefs.push(...await getChildRefs(prefixRef))
            }))
        })
        return childRefs
    }


    const deleteFolder = async (storageRef: StorageReference) => {
        console.log("Deleting folder", storageRef.name)
        const refsToDelete: StorageReference[] = await getChildRefs(storageRef)
        var delete_counter = 0
        await Promise.all(refsToDelete.map(async (ref) => {
            await deleteFile(ref).then(() => {
                console.log("deleted:", ref.fullPath)
                delete_counter += 1
            })
        }))
        console.log("Deletion complete. ", delete_counter, "/", refsToDelete.length, "files deleted")
        loadFiles()
        setFolderDeleteRef(undefined)
        setFolderDeletePromt(false);
    }

    const handleOpenFolderDeletePromt = (storageRef: StorageReference) => {
        setFolderDeleteRef(storageRef)
        setFolderDeletePromt(true)
    };

    const handleCloseFolderDeletePromt = () => {
        setFolderDeleteRef(undefined)
        setFolderDeletePromt(false)
    };

    useEffect(() => {
        loadFiles().then(() => { setPage(0) })
    }, [folderPrefix])

    useEffect(() => {
        loadFiles()
        console.log("refresh")
    }, [props.refresh])

    const displayRow = (item: ItemTuple) => {
        if (!item[1].isDir) {
            return <FileRowComponent {...{
                storageRef: item[0],
                metadata: item[1],
                handleDirChange: handleDirChange,
                rowHeightRef: rowHeightRef,
                isDataManager: props.isDataManager,
                isIncidentManager: props.isIncidentManager,
                handleOpenFolderDeletePromt: handleOpenFolderDeletePromt,
                selectedFileIds: selectedFileIds,
                handleSelectOne: handleSelectOne,
                downloadItems: downloadItems,
                deleteItem: deleteItem,
                loadFiles: loadFiles
            }} />
        } else {
            return <FolderRowComponent {...{
                storageRef: item[0],
                metadata: item[1],
                handleDirChange: handleDirChange,
                rowHeightRef: rowHeightRef,
                isDataManager: props.isDataManager,
                handleOpenFolderDeletePromt: handleOpenFolderDeletePromt
            }} />
        }
    }

    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container alignItems="center" justifyContent={"space-between"} flexDirection="row" xs={12}>
                    <Grid>
                        {!props.isIncidentManager && DataManagerBreadcrumbs({ folderPrefix: folderPrefix, handleDirChange: handleDirChange })}
                    </Grid>
                    <Grid>
                        {(props.setOpenUploadModal !== undefined) && (!props.isIncidentManager) &&
                            (<Tooltip title="Upload file(s)">
                                <IconButton onClick={() => {
                                    //@ts-ignore 
                                    props.setOpenUploadModal(true)
                                }}>
                                    <Upload />
                                </IconButton>
                            </Tooltip>)
                        }
                        {!props.isIncidentManager && <Tooltip title="Create folder">
                            <IconButton onClick={handleOpenFolderPromt}>
                                <CreateNewFolderRoundedIcon />
                            </IconButton>
                        </Tooltip>}
                    </Grid>
                </Grid>
            </Box>
            <Card ref={tableHeightRef} sx={{ height: "90%", flexDirection: "column", display: "flex" }}>
                <DndProvider backend={HTML5Backend}>
                    <PerfectScrollbar>
                        <Box >
                            <TableContainer>
                                <Table sx={{ tableLayout: "fixed" }} >
                                    <TableHead>
                                        <StyledTableRow >
                                            {!props.isDataManager &&
                                                (<TableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={selectedFileIds.length === items.length}
                                                        indeterminate={
                                                            selectedFileIds.length > 0
                                                            && selectedFileIds.length < items.length
                                                        }
                                                        color="primary"
                                                        onChange={handleSelectAll}
                                                        sx={{ paddingLeft: "0px!important" }}
                                                    />
                                                </TableCell>)
                                            }
                                            {props.isDataManager &&
                                                <TableCell sx={{ fontWeight: "bold", width: "5%", minWidth: "1em" }}>

                                                </TableCell>
                                            }
                                            <TableCell sx={{ fontWeight: "bold", width: "30%", maxWidth: "30%" }}>
                                                Name
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: "bold", padding: "0px" }}>
                                                Upload Date
                                            </TableCell>
                                            {/* <TableCell sx={{ fontWeight: "bold" }}>
                                        Location
                                    </TableCell> */}
                                            {props.isDataManager &&
                                                <TableCell sx={{ fontWeight: "bold" }}>
                                                    Size
                                                </TableCell>
                                            }
                                            {(props.isDataManager || props.isIncidentManager) &&
                                                <TableCell sx={{ fontWeight: "bold" }}>
                                                    Actions
                                                </TableCell>
                                            }
                                        </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                        {items.slice(page * limit, page * limit + limit).map(displayRow)}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </PerfectScrollbar>
                    <Box sx={{ flexGrow: 1 }}></Box>
                    <TablePagination
                        component="div"
                        // was customers.length
                        onPageChange={handlePageChange}
                        // TODO onRowsPerPageChange={handleLimitChange}
                        count={items.length}
                        page={page}
                        rowsPerPage={limit}
                        rowsPerPageOptions={pageOptions}
                        sx={{ overflow: "hidden" }}
                    />
                </DndProvider>
            </Card>
            <Dialog open={openFolderPromt} onClose={handleCloseFolderPromt}>
                <DialogTitle>Create a new folder</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="folderName"
                        label="Folder Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={folderName}
                        onChange={e => {
                            setFolderName(e.target.value)
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseFolderPromt}>Cancel</Button>
                    <Button onClick={createFolderHandler}>Create</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={folderDeletePromt} onClose={handleCloseFolderDeletePromt}>
                <DialogTitle>Delete folder</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You are about to delete the folder `{folderDeleteRef != undefined ? folderDeleteRef.name : "undefined"}` and all of its content. Are you sure you want to continure?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseFolderDeletePromt}>Cancel</Button>
                    <Button onClick={async () => { if (folderDeleteRef != undefined) deleteFolder(folderDeleteRef) }}>Delete Folder</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};