import { getFunctions, httpsCallable } from "firebase/functions";
import { regions } from "../../constants/regions";
import { app } from "../../firebase-config";

export interface StartLivestreamParams {
    instanceName: string
}

export const startLivestream = async (props: StartLivestreamParams) => {
    const functions = getFunctions(app, "us-west1");
    const analysisAPI = httpsCallable(functions, 'startLivestreaming');
    //TODO: live version instead
    return await analysisAPI({ params: { name: props.instanceName, droneType: "l3_harris", IP: "10.138.0.4", bucket: "endurus-site-us" } })
        .then((result: any) => {
            console.log("started", result)
            return result
        })
        .catch((e) => console.log(e))
}