import { Box, Button, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { ReactElement } from 'react';
import { theme } from '../../constants/theme';
import { NavLink } from 'react-router-dom';
import "./index.css"

export interface NavItemProps {
    href: string,
    icon: ReactElement,
    title: string,
    open: boolean
}

export const NavItem = (props: NavItemProps) => {

    const isActive = true

    return (
        <ListItem
            key={props.title}
            disablePadding
            sx={{
                display: 'block'
            }}
        >
            <NavLink
                to={props.href}
                key={props.href}
                className={({ isActive }) => (isActive ? 'active' : 'inactive')}
            >
                <ListItemButton
                    sx={{
                    minHeight: 48,
                    justifyContent: props.open ? 'initial' : 'center',
                    px: 2
                    }}
                >
                    <ListItemIcon
                    sx={{
                        minWidth: 0,
                        mr: props.open ? 1.5 : 'auto',
                        justifyContent: 'center'
                    }}
                    >
                        {props.icon}
                    </ListItemIcon>
                    <ListItemText primary={props.title} sx={{ opacity: props.open ? 1 : 0 }} />
                </ListItemButton>
            </NavLink>
        </ListItem >
    );
};