import { TabList } from "@material-ui/lab"
import { Box, Button, Divider, InputAdornment, Menu, MenuItem, Tab, TextField } from "@mui/material"
import { theme } from "../../../../../../constants/theme"
import { History, Share, Add } from "@mui/icons-material"
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

export interface IncidentToolbarProps {
    setTabValue: Function,
    incidentName: string,
    setOpenUploadModal: Function,
    setOpenDataAnalyzerModal: Function
}

export const IncidentToolbar = (props: IncidentToolbarProps) => {

    return (
        <div style={{ width: "100%", marginTop: "5px" }}>
            {/* <Button onClick={() => navigate("/client-portal/incidents")}>{"<-"}</Button> */}
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <Box>
                    <h3 style={{ marginLeft: "2px", marginTop: '15px', marginBottom: '10px' }}>{props.incidentName}</h3>
                </Box>
                <Box
                    component="main"
                    sx={{
                        py: 1,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                        fontSize: theme.fontSizeMedium
                    }}
                >
                    <TabList onChange={(event: any, value: string) => props.setTabValue(value)} variant={"standard"} TabIndicatorProps={{ style: { display: "none" } }}>
                        <Tab value={"0"} label="Data" disableRipple={true} />
                        <Tab value={"1"} label="Maps" disableRipple={true} />
                        {/* <Tab value={"2"} label="Livestream" disableRipple={true} /> */}
                    </TabList>
                </Box>
                <Box sx={{ alignItems: "center", display: "flex", flexDirection: "row" }}>
                    {/* <button style={{ cursor: "pointer", backgroundColor: "transparent" }}><Share /></button>
                    <button style={{ cursor: "pointer", backgroundColor: "transparent" }}><History /></button> */}
                    <PopupState variant="popover" popupId="demo-popup-menu">
                        {(popupState) => (
                            <>
                                <Button endIcon={<Add />} variant="contained" sx={{ height: "100%" }} {...bindTrigger(popupState)}>
                                    New
                                </Button>
                                <Menu {...bindMenu(popupState)}>
                                    <MenuItem onClick={() => { popupState.close; props.setTabValue("0"); props.setOpenUploadModal(true) }}>Data</MenuItem>
                                    <MenuItem onClick={() => { popupState.close; props.setTabValue("1"); props.setOpenDataAnalyzerModal(true) }}>Map</MenuItem>
                                </Menu>
                            </>
                        )}
                    </PopupState>
                </Box>
            </Box>
            <Divider sx={{ py: 1 }}></Divider>
        </div >
    )
}