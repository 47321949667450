import { useEffect, useState } from "react"
import { getMapFromStorageUsingRunId } from "../../../../actions/storage"
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { theme } from "../../../../constants/theme";



export interface DataAnalyzerResultsMapInterface {
    urid: string,
    isLivestream?: boolean
}

export const DataAnalyzerResultsMap = (props: DataAnalyzerResultsMapInterface) => {

    const [htmlToLoad, setHTMLToLoad] = useState<string | undefined>()
    const [currentInterval, setCurrentInterval] = useState<NodeJS.Timer | undefined>()

    useEffect(() => {
        getMapFromStorageUsingRunId({ urid: props.urid, isLivestream: props.isLivestream }).then((html) => {
            setHTMLToLoad(html)
        })
        if (props.isLivestream) {
            if (currentInterval) {
                console.log(currentInterval)
                //@ts-ignore
                clearInterval(currentInterval)
            }
            const interval = setInterval(() => {
                console.log(props.urid)
                getMapFromStorageUsingRunId({ urid: props.urid, isLivestream: props.isLivestream }).then((html) => {
                    setHTMLToLoad(html)
                })
            }, 100000)
            setCurrentInterval(interval)
        }
    }, [props.urid])

    return (
        <Box sx={{ width: "100%", flexGrow: 1 }}>
            {htmlToLoad && (<iframe id="map-display-iframe" style={{ width: "100%", height: "100%" }} srcDoc={htmlToLoad} />)}
            {!htmlToLoad && (
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <CircularProgress sx={{ color: theme.primary }} />
                </Box>
            )}
        </Box>
    )
}