import { getAuth } from "firebase/auth"
import { collection, doc, getDocs, getFirestore, query, getDoc, addDoc, where } from "firebase/firestore"
import { app } from "../firebase-config"
import { LiteFileRef } from "../../public/interfaces/LiteFileRef"

export interface RunDataInterface {
    fileRefs: LiteFileRef[],
    name: string,
    urid: string,
    droneType: string,
    startTime: string
}

export const logRunToDatabase = async (props: RunDataInterface) => {
    const db = getFirestore(app)
    const uuid = getAuth().currentUser?.uid

    const runs = collection(db, "runs");

    return await addDoc(runs, { ...props, uuid }).then((res) => {
    }).catch(
        e => {
            console.log(e)
        }
    )
}

export const getRunFromDatabase = async (urid: string) => {
    const db = getFirestore(app)
    const uuid = getAuth().currentUser?.uid

    const runs = collection(db, "runs");
    const q = query(runs, where("urid", "==", urid))

    console.log(urid)

    return await getDocs(q).then((res) => {
        console.log(res)
        const ul = res.docs[0].data()
        return ul
    }).catch(
        e => {
            console.log(e)
        }
    )
}