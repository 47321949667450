import { getStorage, listAll, ref, uploadBytes } from "firebase/storage"
import { regionToBucketMapping } from "../constants/regions"
import { getUserOrg } from "./organization"
import { app } from "../firebase-config"


export const createFolder = async (folderPrefix: string, folderName: string) => {
    // Get Firebase storage info
    const org = await getUserOrg()
    if (org === undefined) {
        return undefined
    }
    const uoid: string = org.uoid
    const orgRegion: string = org.region
    const bucketURL = Object(regionToBucketMapping)[orgRegion] // TODO: what if this returns null?
    const storage = getStorage(app, bucketURL)
    const basePath = uoid + '/core' + (folderPrefix !== '/' ? folderPrefix : '')

    // check if folder already exists
    const storageRef = ref(storage, basePath) // TODO add current wdir
    var existsBool: boolean = true
    await listAll(storageRef).then((res) => {
        existsBool = res.prefixes.filter(p => p.name === folderName).length > 0
    })

    // Upload empty
    if (!existsBool) {
        const folderRef = ref(storage, basePath + '/' + folderName + '/.FS_Store') // TODO add current wdir
        return uploadBytes(folderRef, new Uint8Array([])).then((snapshot) => {
            return folderPrefix + "/" + folderName
            // setFolderPrefix(newPrefix)
            // setFolderName('')
            // setFolderPromtOpen(false)
            // if (props.setCurrentFolder) {
            //     props.setCurrentFolder(newPrefix)
            // }
        }).catch((err) => {
            return err
            // setFolderName('')
            // setFolderPromtOpen(false)
        })
    } else {
        return Error("Folder already exists!")
        // setFolderName('')
        // setFolderPromtOpen(false)
    }
}