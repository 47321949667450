import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { getRunFromDatabase } from '../../../../../actions/runs';
import { minSec } from '../../../../../actions/util';
import { overheads, time_per_mb } from '../../../../../constants/runtimes';
import { Tooltip } from '@mui/material';

export const RunProgressWithLabel = (props: LinearProgressProps & { urid: string }) => {
    const [progress, setProgress] = useState(0)

    useEffect(() => {
        getRunFromDatabase(props.urid).then((run) => {
            if (run) {
                //@ts-ignore
                const total_megabytes = run.fileRefs.reduce((a, ref) => ref.megabytes + a, 0)
                const expectedRuntime = (time_per_mb.get(run.droneType) * total_megabytes + overheads.get(run.droneType)) * 60
                const startTime: Date = new Date(Number(run.startTime))
                const diffInSeconds = (new Date().getTime() - startTime.getTime()) / 1000
                setProgress(diffInSeconds < expectedRuntime ? (diffInSeconds / expectedRuntime) * 100 : 99)
            }
        }).catch((e) => console.log(e))

    }, [props.urid]);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title={`${Math.round(
                progress,
            )}%`}>
                <Box sx={{ width: '100%' }}>
                    <LinearProgress sx={{ "& .MuiLinearProgress-bar": { transition: "none" } }} variant="determinate" {...props} value={progress} />
                </Box>
            </Tooltip>
        </Box>
    );
}