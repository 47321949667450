import { Box, Container, Card } from '@mui/material';

export const InvalidLicence = () => {
    return (
        <>
            <title>
                Invalid Licence | Endurus
            </title>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 2
                }}

            >
                <Container maxWidth={false} sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <Card sx={{ height: "50vh", width: "50vh", paddingTop: "5vh", paddingLeft: "1vh", marginTop: "5vh" }}>
                        <h3>We're sorry!</h3>
                        <br></br>
                        <p>It looks like your licence is either expired, or doesn't have the required add-ons to access that page. Please contact an Endurus administrator to renew your licence.</p>
                    </Card>
                </Container>
            </Box>
        </>)
}