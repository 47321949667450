import { useCallback, useState } from 'react'
import { getAuth, sendPasswordResetEmail, sendSignInLinkToEmail, signInWithEmailAndPassword } from 'firebase/auth'
import { Link, Route, Routes, Navigate, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import Navbar from '../../../components/navbar'
import Footer from '../../../components/footer'
import './index.css'

const ResetPassword = () => {

    const [resetSent, setResetSent] = useState(false)
    const auth = getAuth();


    const handleSubmit = useCallback(async (e: any) => {
        e.preventDefault()
        const { email } = e.target.elements
        sendPasswordResetEmail(auth, email.value)
            .then(() => { setResetSent(true) })
            .catch((e) => { alert(e); });
    }, [])

    return (
        <div className="forgot-password-container">
            <Helmet>
                <title>Endurus</title>
                <meta property="og:title" content="Endurus Forgot Password" />
            </Helmet>
            <div className="forgot-password-forgot-password">
                <Navbar {...{ PrimaryButtonText: "Client Login", PrimaryButtonPath: "/login" }}></Navbar>
                <div className="forgot-password-container-1">
                    <form onSubmit={handleSubmit}>
                        <p>If you have forgotten your password, please put your email in the box </p>
                        <p>below and, if an account exists, we'll send you a reset link.</p>
                        <br></br>
                        <input name="email" placeholder="you@example.com" type="email" className="input-container-input input" />
                        <br></br>
                        <br></br>
                        {!resetSent && <button type="submit" className="input-container-button button-primary button">Submit</button>}
                        {resetSent && <button className="input-container-button button-primary button" style={{ backgroundColor: "#ef4e20ff" }}>Reset email sent</button>}
                    </form>
                </div>
                <Footer ></Footer>
            </div>
        </div>
    )
}

export default ResetPassword