import { Box, Container, Card, Button, Tooltip } from '@mui/material';
import { getLatestFrameFromStorageUsingRunId } from '../../../../actions/storage';
import { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { theme } from '../../../../constants/theme'

export interface LivestreamLatestFrameProps {
    urid: string | undefined
}

export const LivestreamLatestFrame = (props: LivestreamLatestFrameProps) => {

    const [img, setImg] = useState<string | undefined>()
    const [currentInterval, setCurrentInterval] = useState<NodeJS.Timer | undefined>()

    useEffect(() => {
        if (props.urid) {
            getLatestFrameFromStorageUsingRunId({ ...{ urid: props.urid } }).then((img) => setImg(img))
            if (currentInterval) {
                console.log(currentInterval)
                //@ts-ignore
                clearInterval(currentInterval)
            }
            const interval = setInterval(() => {
                //@ts-ignore  we literally just checked if it was defined
                getLatestFrameFromStorageUsingRunId({ ...{ urid: props.urid } }).then((img) => setImg(img))
            }, 10000)
            setCurrentInterval(interval)
        }
    }, [props.urid])

    return (
        <Card sx={{ height: "50%", width: "80%", marginTop: "10px" }}>
            {img &&
                (<p>Most recent image:</p>) &&
                (<img style={{ height: "100%", width: "100%" }} src={img} />)
            }
            {!img && props.urid && (
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <CircularProgress sx={{ color: theme.primary }} />
                </Box>
            )}
        </Card>
    )
}