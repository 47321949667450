import { collection, doc, getDocs, getFirestore, query, updateDoc, where } from "firebase/firestore";
import { app } from "../../firebase-config";
import { getAuth } from "firebase/auth";

export const addAnalysisToIncident = async (uiid: string, urid: string) => {
    const db = getFirestore(app)
    const uuid = getAuth().currentUser?.uid

    const incidents = collection(db, "incidents");
    // we need the where("uuid" == uuid) clause here to validate with firebase rules
    const q = query(incidents, where("uuid", "==", uuid), where("uiid", "==", uiid))

    return await getDocs(q).then(async (res) => {
        const incident = res.docs[0]
        const analyses: string[] = incident.data().analyses
        analyses.push(urid)
        await updateDoc(doc(db, "incidents", incident.id), {
            analyses: analyses
        }).then(() => {
            return true
        })
    }).catch(
        e => {
            console.log(e)
            return false
        }
    )

}