import {
    TableCell,
    Button,
    Tooltip,
} from '@mui/material';
import { StorageReference } from 'firebase/storage';
import { getIconFromContentType } from '../../../../actions/icons';
import { FileData, StyledTableRow } from '..'
import DeleteIcon from '@mui/icons-material/Delete'
import { theme } from '../../../../constants/theme';
import { useDrop } from 'react-dnd';
import useMergedRef from '@react-hook/merged-ref';
import { getOrganizationStorageReference } from '../../../../actions/storage';


export interface FolderRowComponentProps {
    storageRef: StorageReference,
    metadata: FileData,
    handleDirChange: Function,
    rowHeightRef: React.MutableRefObject<null>,
    isDataManager: boolean,
    handleOpenFolderDeletePromt: Function
}

const FolderRowComponent = (props: FolderRowComponentProps) => {
    const [{ canDrop, isOver }, drop] = useDrop(() => ({
        accept: 'box',
        drop: () => ({ name: props.storageRef.fullPath.toString() }),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    }))

    let backgroundColor = '#e8e8e8'
    if (canDrop && isOver) {
        backgroundColor = '#65b8a3'
    } else if (canDrop) {
        backgroundColor = '#7c909b'
    }
    return (
        <StyledTableRow ref={useMergedRef(props.rowHeightRef, drop)} key={props.storageRef.name} sx={{ '&:hover': { cursor: "pointer" }, backgroundColor: backgroundColor }}>
            {!props.isDataManager &&
                <TableCell onClick={() => props.handleDirChange(props.metadata.id)}>
                    <div style={{ overflow: "hidden", height: theme.rowHeight, display: "flex", justifyContent: "center" }}>
                        {getIconFromContentType("folder")}
                    </div>
                </TableCell>
            }
            {props.isDataManager &&
                <TableCell onClick={() => props.handleDirChange(props.metadata.id)}>
                    <div style={{ overflow: "hidden", height: theme.rowHeight }}>
                        {getIconFromContentType("folder")}
                    </div>
                </TableCell>
            }
            <TableCell sx={{ width: "30%", maxWidth: "30%" }} onClick={() => props.handleDirChange(props.metadata.id)}>
                <div style={{ overflow: "hidden", height: theme.rowHeight }}>
                    {props.storageRef.name}
                </div>
            </TableCell>
            <TableCell onClick={() => props.handleDirChange(props.metadata.id)} />
            {props.isDataManager &&
                <TableCell onClick={() => props.handleDirChange(props.metadata.id)} />
            }
            {/* MATCHES LOCATION <TableCell></TableCell> */}
            {props.isDataManager &&
                <TableCell sx={{ display: "table-cell" }}>
                    <Tooltip title="Delete">
                        <Button sx={{ minWidth: "30px", width: "30px", paddingTop: "0px!important", paddingBottom: "0px!important", color: theme.secondary }} startIcon={(<DeleteIcon fontSize="small" />)} onClick={() => props.handleOpenFolderDeletePromt(props.storageRef)} />
                    </Tooltip>
                </TableCell>
            }
        </StyledTableRow>
    )
}

export default FolderRowComponent