import { useEffect, useState } from "react";
import { app } from "../../../firebase-config";

import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Box,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    Button,
    Tooltip,
    Container
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import MapIcon from '@mui/icons-material/Map'
import InfoIcon from '@mui/icons-material/Info'
import { getStatusBadge } from "../../../actions/data-analyzer";
import { getPastLivestreams } from "../../../actions/livestreaming/get-past-livestreams";
import { DataAnalyzerResultsMap } from "../../data-analyzer/data-analyzer-results/data-analyzer-results-map";
import { theme } from "../../../constants/theme";
import { getAnalysisMetadataFromStorageUsingRunID, getDownloadFromItems, getFileReferenceFromStorageUsingRunId } from "../../../actions/storage";
import { getAddressFromLatLon } from "../../../actions/geocoding";
import { styled } from "@mui/material/styles";
import { downloadMapGeojson, downloadMapShapefile } from "../../../actions/maps/download-maps";

const MINUTE_IN_MS = 60000

export interface LivestreamResults {
}

export const LivestreamResults = (props: LivestreamResults) => {

    const [logs, setLogs] = useState<JSON[]>([])
    const [displayId, setDisplayId] = useState<string | undefined>()
    const [displayItem, setDisplayItem] = useState<any | undefined>()
    const [startingLogs, setStartingLogs] = useState<JSON[]>([])
    const [displayMetadata, setDisplayMetadata] = useState<any | undefined>()
    const [address, setAddress] = useState<string>("")
    const [uridInfo, setUridInfo] = useState<string | undefined>()

    const [openResultInfo, setOpenResultInfo] = useState(false)
    const [currentInfoItem, setCurrentInfoItem] = useState()
    const [currentInfoItemStatus, setCurrentInfoItemStatus] = useState()

    useEffect(() => {
        getPastLivestreams(setStartingLogs)
    }, [])


    //TODO: WARNING THIS MIGHT COST A LOT IN QUERIES
    useEffect(() => {
        const interval = setInterval(() => {
            getPastLivestreams(setLogs)
        }, MINUTE_IN_MS / 10);

        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [])

    const getResultMetadata = () => {
        if (displayId) {
            getAnalysisMetadataFromStorageUsingRunID({ urid: displayId, isLivestream: true }).then((res) => {
                console.log(res)
                setDisplayMetadata(res)
                getAddressFromLatLon(res?.coords[0], res?.coords[1]).then((address) => {
                    setAddress(address ?? "")
                })
            })
        }
    }

    useEffect(() => {
        getResultMetadata()
    }, [displayId])

    const setDisplayItemFromId = (id: string) => {
        setDisplayId(id)
        const item = logs.filter((log: any) => log.unique_run_id == id)
        setDisplayItem(item[0])
    }

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        [`&.${tableRowClasses.root}`]: {
            height: "5vh"
        },
    }))


    return (
        <Container sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", height: "70vh" }}>
            <Card sx={{ width: "35%", overflowY: "scroll", overflowX: "hidden", height: "100%" }} >
                <PerfectScrollbar>
                    {
                        //No need, automatic now
                        // <Box sx={{ width: "100%", display: "flex", flexDirection: "row-reverse" }}>
                        //     <Button startIcon={(<RefreshIcon fontSize="small" />)} onClick={() => getDataAnalysis(setLogs)} sx={{ marginLeft: "auto" }} />
                        // </Box>
                    }
                    <Table>
                        <TableHead>
                            <StyledTableRow>
                                <TableCell sx={{ fontSize: theme.fontSizeMedium, fontWeight: "Bold" }}>
                                    Name
                                </TableCell>
                                <TableCell sx={{ fontSize: theme.fontSizeMedium, fontWeight: "Bold" }}>
                                    Status
                                </TableCell>
                                {/* <TableCell sx={{fontSize: theme.fontSizeMedium, fontWeight: "Bold"}}>
                                    Last Updated
                                </TableCell> */}
                                {/* <TableCell sx={{fontSize: theme.fontSizeMedium, fontWeight: "Bold"}}>
                                    Id
                                </TableCell> */}
                                <TableCell sx={{ fontSize: theme.fontSizeMedium, fontWeight: "Bold" }}>
                                    Actions
                                </TableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {logs.map((item: any) => {
                                let otherData;
                                try {
                                    otherData = JSON.parse(item.output_path)
                                }
                                catch (e) {
                                    return (<></>)
                                }
                                const time = new Date(item.time)
                                return (
                                    <StyledTableRow key={item.unique_run_id}>
                                        <TableCell sx={{}}>{otherData.name}</TableCell>
                                        <TableCell>{getStatusBadge(item.status, time, item.unique_run_id)}</TableCell>
                                        {/* <TableCell>{time.toUTCString()}</TableCell> */}
                                        {/* <TableCell sx={{ fontsize: "small" }}>{item.unique_run_id}</TableCell> */}
                                        <TableCell sx={{}}>
                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                {item.status == "Complete" && (<Tooltip title="View" >
                                                    <Button sx={{ color: theme.secondary, margin: "0px!important", paddingRight: "2px!important", paddingLeft: "2px!important" }} startIcon={(<MapIcon fontSize="small" />)} onClick={() => { setDisplayItemFromId(item.unique_run_id) }} />
                                                </Tooltip>)}
                                                {item.status == "Complete" && (<Tooltip title="Info">
                                                    <Button sx={{ color: theme.secondary, margin: "0px!important", paddingRight: "2px!important", paddingLeft: "2px!important" }} startIcon={(<InfoIcon fontSize="small" />)} onClick={() => { setCurrentInfoItem(item.output_path); setCurrentInfoItemStatus(item.status); setOpenResultInfo(true); setUridInfo(item.unique_run_id) }} />
                                                </Tooltip>)}
                                                {item.status == "Error" && (<Tooltip title="Info">
                                                    <Button sx={{ color: theme.secondary, margin: "0px!important", paddingRight: "2px!important", paddingLeft: "2px!important" }} startIcon={(<InfoIcon fontSize="small" />)} onClick={() => { setCurrentInfoItem(item.output_path); setCurrentInfoItemStatus(item.status); setOpenResultInfo(true); setUridInfo(item.unique_run_id) }} />
                                                </Tooltip>)}
                                            </div>
                                        </TableCell>
                                    </StyledTableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </PerfectScrollbar>
            </Card>
            <Card sx={{ width: "70%" }}>
                {!displayItem && !displayId && (
                    <Box sx={{ display: "flex", height: "100%" }}>
                        <Box sx={{ alignItems: "center", display: "flex", width: "100%" }}>
                            <h3 style={{ width: "80%", textAlign: "center", marginLeft: "10%", marginRight: "10%" }}>Select a completed run using {<MapIcon></MapIcon>} to see the output!</h3>
                        </Box>
                    </Box>
                )}
                {displayItem && displayId && (
                    <Box sx={{ display: "flex", height: "100%", width: "100%", flexDirection: "column" }}>
                        <DataAnalyzerResultsMap {...{ urid: displayId, isLivestream: true }} />
                        <Box sx={{ height: "50%", width: "100%", display: "flex", flexDirection: "row" }}>
                            <Card sx={{ width: "50%", borderRadius: "0px" }}>
                                <Box className="resultDisplay" sx={{ ml: "2vh", mt: "2vh", mr: "6vh" }}>
                                    <h4>{JSON.parse(displayItem.output_path).name}</h4>
                                    <br />
                                    <h5>Location</h5>
                                    {/* TODO: reverse geocode */}
                                    {/* <p>{displayMetadata?.coords.map((coord: number) => roundToTwo(coord)).join(", ")}</p> */}
                                    {/* @ts-ignore */}
                                    <p>{address}</p>
                                    <br />
                                    <h5>Time</h5>
                                    <p>{displayMetadata?.timestamp}</p>
                                </Box>
                            </Card>
                            <Card className="resultDisplay" sx={{ width: "50%", borderRadius: "0px", justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column" }}>
                                <Button
                                    onClick={() => { downloadMapGeojson(displayId, true) }}
                                    sx={{
                                        width: "70%",
                                        backgroundColor: theme.primary,
                                        color: "white",
                                        '&:hover': {
                                            backgroundColor: theme.bigred
                                        },
                                        fontSize: theme.fontSizeMedium
                                    }}>
                                    Export as GeoJSON
                                </Button>
                                <Button
                                    onClick={() => { downloadMapShapefile(displayId, true) }}
                                    sx={{
                                        width: "70%",
                                        backgroundColor: theme.primary,
                                        color: "white",
                                        '&:hover': {
                                            backgroundColor: theme.bigred
                                        },
                                        mt: "3vh",
                                        fontSize: theme.fontSizeMedium
                                    }}>
                                    Export as ArcGIS Shapefile
                                </Button>
                            </Card>
                        </Box>
                    </Box>
                )
                }
            </Card>
            {/* <TablePagination
                    component="div"
                    // was customers.length
                    count={0}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleLimitChange}
                    page={page}
                    rowsPerPage={limit}
                    rowsPerPageOptions={[5, 10, 25]}
                /> */}
        </Container>
    )
}