import { collection, getDocs, getFirestore, query, where } from "firebase/firestore"
import { app } from "../../firebase-config"
import { getAuth } from "firebase/auth"
import { getUserOrg } from "../organization"


export const getOrgLivestreamInfo = async () => {
    const db = getFirestore(app)
    const auth = getAuth().currentUser
    if (!auth) {
        return
    }
    const uoid = await getUserOrg().then((res) => { return res!.uoid })

    const livestream_instances = collection(db, "livestreaming");
    const q = query(livestream_instances, where("uoid", "==", uoid))
    return await getDocs(q).then((res) => {
        if (res.docs.length > 1) {
            alert("Livestream configuration error, please contact an administrator")
        }
        return { data: res.docs[0].data() }
    }).catch(
        (e) => {
            console.log(e)
        })
}