import { Box, Container } from "@mui/material"
import { IncidentsToolbar } from "./incidents-toolbar"
import { useState } from "react"
import { IncidentsOverview } from "./incidents-overview"


export const Incidents = () => {

    const [displayState, setDisplayState] = useState<"cards" | "list">("cards")
    const [refresh, toggleRefresh] = useState(false)

    return (
        <>
            <title>
                Data Manager | Endurus
            </title>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 2
                }}

            >
                <Container sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
                    <Box >
                        <IncidentsToolbar {...{ displayState, setDisplayState, refresh, toggleRefresh }} />
                    </Box>
                    <Box sx={{}}>
                        <IncidentsOverview {...{ displayState, refresh }}></IncidentsOverview>
                    </Box>
                </Container>
            </Box>
        </>
    )
}