import { getAuth } from "firebase/auth"
import { collection, getDocs, getFirestore, orderBy, query, where } from "firebase/firestore"
import { app } from "../../firebase-config"
import { IncidentDataInterface } from "../../interfaces/IncidentDataInterface"
import { getUserOrg } from "../organization"


export const getIncidentsFromDatabase = async () => {
    const db = getFirestore(app)
    const uuid = getAuth().currentUser?.uid
    const org = await getUserOrg()

    if (org === undefined || uuid === undefined) {
        return
    }

    // no need to validate auth of uuid here as we check in the Firebase dataset rules
    const incidents = collection(db, "incidents");
    //TODO: we need to safeguard this in firebase rules
    const q = query(incidents, orderBy("uoid"), where("uoid", "==", org?.uoid), orderBy("createdDate", "desc"))

    return await getDocs(q).then((res) => {
        console.log(res)
        const ul = res.docs
        return ul
    }).catch(
        e => {
            console.log(e)
        }
    )
}

export const getIncidentFromDatabase = async (uiid: string) => {
    const db = getFirestore(app)
    const uuid = getAuth().currentUser?.uid
    const org = await getUserOrg()

    if (org === undefined || uuid === undefined) {
        return
    }

    const incidents = collection(db, "incidents");
    // we need the where("uuid" == uuid) clause here to validate with firebase rules
    const q = query(incidents, where("uiid", "==", uiid))

    return await getDocs(q).then((res) => {
        console.log(res)
        const ul = res.docs[0].data()
        return ul
    }).catch(
        e => {
            console.log(e)
        }
    )
}