import { Box, Container, Card, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { DataAnalyzerResultsMap } from '../../../components/data-analyzer/data-analyzer-results/data-analyzer-results-map';
import { LivestreamListenerManager } from '../../../components/livestream/livestream-setup/livestream-listener-manager';
import { LivestreamLatestFrame } from '../../../components/livestream/livestream-setup/livestream-latest-frame';
import { downloadMapGeojson, downloadMapShapefile } from '../../../actions/maps/download-maps';
import { theme } from '../../../constants/theme';

export const LivestreamSetup = () => {
    const [urid, setUrid] = useState<string | undefined>()

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 2,
                height: "75vh",
                padding: "0px"
            }}

        >
            <Container sx={{ height: "100%", display: "flex", flexDirection: "row", width: "100%" }}>
                <Box sx={{ width: "50%", display: "flex", flexDirection: "column", height: "100%", justifyContent: "center" }}>
                    <LivestreamListenerManager  {...{ urid: urid, setUrid: setUrid }} />
                    <LivestreamLatestFrame {...{ urid: urid }} />
                </Box>
                <Box sx={{ width: "50%", height: "100%" }}>
                    {urid && (<DataAnalyzerResultsMap {...{ urid: urid, height: "80%", isLivestream: true }} />)}
                    {urid &&
                        <Card className="resultDisplay" sx={{ height: "20%", width: "100%", borderRadius: "0px", justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "row" }}>
                            <Button
                                onClick={() => { downloadMapGeojson(urid, true) }}
                                sx={{
                                    width: "70%",
                                    backgroundColor: theme.primary,
                                    color: "white",
                                    '&:hover': {
                                        backgroundColor: theme.bigred
                                    },
                                    fontSize: theme.fontSizeMedium
                                }}>
                                Export as GeoJSON
                            </Button>
                            <Button
                                onClick={() => { downloadMapShapefile(urid, true) }}
                                sx={{
                                    width: "70%",
                                    backgroundColor: theme.primary,
                                    color: "white",
                                    '&:hover': {
                                        backgroundColor: theme.bigred
                                    },
                                    mt: "3vh",
                                    fontSize: theme.fontSizeMedium
                                }}>
                                Export as ArcGIS Shapefile
                            </Button>
                        </Card>
                    }
                </Box>
            </Container>
        </Box>
    )
};