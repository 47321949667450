import { Box, Button, Card, Typography } from "@mui/material"
import { DataAnalyzerResultsMap } from "../../../../../../../components/data-analyzer/data-analyzer-results/data-analyzer-results-map"
import { useEffect, useState } from "react"
import { DataAnalyzerResultsCard } from "../../../../../../../components/data-analyzer/data-analyzer-results/data-analyzer-results-card"
import { DataAnalyzerSetup } from "../../../../../../../components/data-analyzer/data-analyzer-setup"
import { IncidentViewsMapDataAnalyzerRuns } from "./incident-views-map-analyzer-runs"
import { ChevronLeft, ChevronRight, MenuOpen } from "@mui/icons-material"

export interface IncidentViewsMapProps {
    openDataAnalyzerModal: boolean,
    setOpenDataAnalyzerModal: Function,
    navigator: Function,
    uiid: string | undefined
}

export const IncidentViewsMap = (props: IncidentViewsMapProps) => {

    const onClose = () => { props.setOpenDataAnalyzerModal(false) }

    const [loiter, setLoiter] = useState<string | undefined>()
    const [displayId, setDisplayId] = useState<string | undefined>()
    const [displayItem, setDisplayItem] = useState<any | undefined>()
    const [open, setOpen] = useState(false)

    const toggleMapBar = () => {
        if (!open) {
            setOpen(true)
        }
        else {
            setOpen(false)
        }
    }

    // TODO: for some reason I need this to force it to update...there's got to be a better solution
    useEffect(() => {
    }, [props.uiid])

    return (
        <Box sx={{ display: "flex", flexDirection: "row", height: "calc(100vh - 130px)" }}>
            <Box sx={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
                <DataAnalyzerResultsMap {...{ urid: displayId ?? "" }} />
                <DataAnalyzerResultsCard {...{ displayId: displayId ?? "", displayItem: displayItem }} />
            </Box>
            <DataAnalyzerSetup {...{ handleClose: onClose, navigator: props.navigator, setLoiter: setLoiter, open: props.openDataAnalyzerModal, onClose, uiid: props.uiid }} />
            <Box sx={{ borderLeft: "1px solid rgba(0, 0, 0, 0.12)", width: open ? "30%" : "5%", height: "100%" }}>
                <Box sx={{ py: "15px" }}>
                    <Button sx={{ float: open ? "left" : "none", width: open ? "inherit" : "100%", minHeight: "10%!important", margin: "0!important" }} onClick={toggleMapBar}>
                        {open ? <ChevronRight /> : <MenuOpen />}
                    </Button>
                    <Typography variant={"body2"} sx={{ display: open ? "block" : "none", ml: "130px", mt: "4px", fontWeight: "bold" }}>Maps</Typography>
                </Box>
                <Box sx={{ opacity: open ? 1 : 0, height: "90%!important" }} >
                    <IncidentViewsMapDataAnalyzerRuns {...{ uiid: props.uiid, displayId, setDisplayId, setDisplayItem, displayItem, loiter: loiter }} />
                </Box>
            </Box>
        </Box>
    )
}