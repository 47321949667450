import { Box, TextField, Button, FormControl, InputLabel, Select, MenuItem, CircularProgress, responsiveFontSizes } from '@mui/material';
import { theme } from '../../../../constants/theme';
import { useEffect, useState } from 'react';
import { LiteFileRef } from '../../../../../public/interfaces/LiteFileRef'
import { drone_types } from '../../../../constants/drone_types';
import { getUserOrgRegion } from '../../../../actions/organization';
import { getAnalysisAPIFromOrgRegion } from '../../../../actions/data-analyzer';
import { ItemTuple } from '../../../../actions/storage';
import { overheads, time_per_mb } from '../../../../constants/runtimes';
import { minSec, roundToTwo } from '../../../../actions/util';
import DialogActions from '@material-ui/core/DialogActions';
import { logRunToDatabase } from '../../../../actions/runs';

import { addAnalysisToIncident } from '../../../../actions/incidents/add-analysis-to-incident';
import { getProfileData } from '../../../../actions/settings/profile';

export interface DataAnalyzerSetupSettingsProps {
    files: ItemTuple[],
    navigator: Function,
    handleClose: Function,
    uiid: string | undefined
}

export const DataAnalyzerSetupSettings = (props: DataAnalyzerSetupSettingsProps) => {

    const [name, setName] = useState("")
    const [fileIds, setFileIds] = useState<string[]>([])
    const [fileRefs, setFileRefs] = useState<LiteFileRef[]>([])
    const [selectedDroneType, setSelectedDroneType] = useState<string>("")
    const [queryStarted, setQueryStarted] = useState(false)

    useEffect(() => {
        const newFileIds = props.files.map(([item, metadata]) => item.name);
        setFileIds(newFileIds);
        const newFileRefs = props.files.map(([item, metadata]) => {
            return {
                "name": item.name,
                "fullPath": item.fullPath,
                "bucket": item.bucket,
                "megabytes": metadata.size * 0.000001
            }
        })
        setFileRefs(newFileRefs)
    }, [props.files])

    useEffect(() => {
        getProfileData().then((value) => {
            if (value.data.default_payload) {
                setSelectedDroneType(value.data.default_payload)
            }
        })
    }, [])

    const handleClose = () => {
        props.handleClose()
    };

    const startAnalysis = async () => {

        setQueryStarted(true)
        const region = await getUserOrgRegion()
        const analysisAPI = getAnalysisAPIFromOrgRegion(region)

        if (selectedDroneType === "") {
            alert("You need to select drone type")
            setQueryStarted(false)
            return
        }

        if (fileRefs.length == 0) {
            alert("You need to select at least one file")
            setQueryStarted(false)
            return
        }

        if (name === "") {
            alert("Please provide an analysis name")
            setQueryStarted(false)
            return
        }

        analysisAPI({ params: { files: fileRefs, name: name, droneType: selectedDroneType } })
            .then((result) => {
                setQueryStarted(false)
                handleClose()
                console.log(result.data)
                //@ts-ignore
                props.setLoiter({ name: name, urid: result.data.message.urid.message })
                //transitions to runs tab
                props.navigator("1")
                //@ts-ignore
                const urid = result.data.message.urid.message
                logRunToDatabase({ fileRefs: fileRefs, name: name, droneType: selectedDroneType, urid: urid, startTime: (new Date()).getTime().toString() })
                if (props.uiid) {
                    addAnalysisToIncident(props.uiid, urid)
                }
            })
            .catch((e) => { console.log(e); setQueryStarted(false) })
    }

    const getExpectedRuntime = () => {
        const total_megabytes = fileRefs.reduce((a, ref) => ref.megabytes + a, 0)
        const est = time_per_mb.get(selectedDroneType) * total_megabytes + overheads.get(selectedDroneType)
        const { minutes, seconds } = minSec(est)
        return (
            <ul style={{ paddingLeft: "15px" }}>
                <li>Runtime: {minutes}m {seconds}s</li>
                <li>Size: {roundToTwo(total_megabytes)}MB</li>
            </ul>
        )
    }

    return (
        <Box sx={{ mt: 1, justifyContent: "space-between", alignItems: "center", display: "flex", flexDirection: "row", fontSize: theme.fontSizeMedium }}>
            <TextField placeholder="Analysis Name" sx={{ minWidth: 150, alignSelf: "center", marginRight: 1 }} onChange={(e) => setName(e.target.value)}>
            </TextField>
            <FormControl sx={{ minWidth: 150, marginRight: 4 }}>
                <InputLabel id="demo-simple-select-label">Payload Type</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedDroneType}
                    label="Payload Type"
                    onChange={(e) => setSelectedDroneType(e.target.value)}
                >
                    {Array.from(drone_types.keys()).map((drone_type_key: any) => { return (< MenuItem sx={{ fontSize: theme.fontSizeMedium }} value={drone_types.get(drone_type_key)}>{drone_type_key}</MenuItem>) })}
                </Select>
            </FormControl>
            <br />
            <br />
            <Box sx={{ minWidth: 150, display: "flex", flexDirection: "column", justifyContent: "left" }}>
                {/* <p>{(fileIds.length > 0) && "Selected file"}{(fileIds.length > 1) && "s"}{(fileIds.length > 0) && ":"}</p>
                {fileIds.map((id) => <p>{id}</p>)} */}
                {(fileRefs.length > 0) && selectedDroneType && (
                    <div>
                        <h4>Estimate:</h4>
                        {getExpectedRuntime()}
                    </div>
                )}
            </Box>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                {!queryStarted && <Button onClick={startAnalysis}>Start Analysis</Button>}
                {queryStarted && <CircularProgress size={"1.5em"}></CircularProgress>}
            </DialogActions>
        </Box >
    )
}