import { Button } from "@mui/material"
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import "./index.css"

export interface ShowOnHoverProps {
    textToDisplay: string,
    copyToClipboard: boolean
}
export const ShowOnHover = (props: ShowOnHoverProps) => {


    return (
        <div className="showchild">
            <Button className="showme" onClick={() => { navigator.clipboard.writeText(props.textToDisplay) }}>{props.textToDisplay}</Button>
        </div>
    )
}