import { Box, Button, Divider, InputAdornment, TextField } from "@mui/material"
import SearchIcon from '@mui/icons-material/Search'
import ViewListIcon from '@mui/icons-material/ViewList';
import WindowIcon from '@mui/icons-material/Window';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useState } from "react";
import { IncidentsToolbarCreateIncidentDialog } from "./incidents-toolbar-create-incident-dialog";

export interface IncidentsToolbarProps {
    displayState: string,
    setDisplayState: Function,
    refresh: boolean,
    toggleRefresh: Function
}

export const IncidentsToolbar = (props: IncidentsToolbarProps) => {

    const [showCreateIncidentDialog, toggleShowCreateIncidentDialog] = useState(false)

    return (
        <div style={{ width: "100%" }}>
            <IncidentsToolbarCreateIncidentDialog {...{ open: showCreateIncidentDialog, setOpenState: toggleShowCreateIncidentDialog, refresh: props.refresh, toggleRefresh: props.toggleRefresh }} />
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <TextField
                    sx={{ maxWidth: 400, width: "50%" }}
                    size="small"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon fontSize="small" />
                            </InputAdornment>
                        )
                    }}
                    placeholder="(search coming soon!)"
                    variant="outlined"
                    disabled
                />
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    {/* <button style={{ cursor: "pointer", backgroundColor: "transparent" }}><FilterListIcon></FilterListIcon></button>
                    {props.displayState != "cards" && <button onClick={() => props.setDisplayState("cards")} style={{ cursor: "pointer", backgroundColor: "transparent" }}><WindowIcon></WindowIcon></button>}
                    {props.displayState == "cards" && <button onClick={() => props.setDisplayState("list")} style={{ cursor: "pointer", backgroundColor: "transparent" }}><ViewListIcon></ViewListIcon></button>} */}
                    <Button variant="contained" sx={{ height: "100%" }} onClick={() => toggleShowCreateIncidentDialog(true)}>New Incident</Button>
                </Box>
            </Box>
            <Divider sx={{ py: 1 }}></Divider>
        </div>
    )
}