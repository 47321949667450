import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import { createIncident } from "../../../../../../actions/incidents/create-indicent"
import { theme } from "../../../../../../constants/theme"
import { useState } from "react"
import { incidentTypes } from "../../../../../../constants/incident_types"


export interface IncidentsToolbarCreateIncidentDialogProps {
    open: boolean,
    setOpenState: Function,
    refresh: boolean,
    toggleRefresh: Function
}

export const IncidentsToolbarCreateIncidentDialog = (props: IncidentsToolbarCreateIncidentDialogProps) => {

    const [selectedIncidentType, setSelectedIncidentType] = useState<string | undefined>()
    const [incidentName, setIncidentName] = useState<string | undefined>()

    const createIncidentHandler = async () => {
        if (selectedIncidentType == undefined) {
            alert("Please select an incident type")
            return
        }
        if (incidentName == undefined || incidentName == "") {
            alert("Please add a name")
            return
        }
        createIncident({ incidentType: selectedIncidentType, name: incidentName }).then(() => {
            props.setOpenState(false)
            props.toggleRefresh(!props.refresh)
        }).catch(
            (e) => { alert(e) }
        )
    }

    return (
        <Dialog open={props.open}>
            <DialogTitle>
                Create new incident
            </DialogTitle>
            <DialogContent>
                <FormControl sx={{ minWidth: 150, margin: 4 }}>
                    <InputLabel id="demo-simple-select-label">Incident Type</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedIncidentType}
                        label="Incident Type"
                        onChange={(e) => setSelectedIncidentType(e.target.value)}
                    >
                        {Array.from(incidentTypes.keys()).map((incidentTypeKey: any) => { return (< MenuItem sx={{ fontSize: theme.fontSizeMedium }} value={incidentTypes.get(incidentTypeKey)}>{incidentTypeKey}</MenuItem>) })}
                    </Select>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="incidentName"
                        label="Incident Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={incidentName}
                        onChange={e => {
                            setIncidentName(e.target.value)
                        }}
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={createIncidentHandler}>Create</Button>
                <Button onClick={() => { props.setOpenState(false) }}>Cancel</Button>
            </DialogActions>
        </Dialog>
    )
}