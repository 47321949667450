import React from 'react'

import PropTypes from 'prop-types'

import './index.css'

const Footer = (props: any) => {
  return (
    <footer className="footer-footer section-container">
      <div className="footer-max-width max-content-container">
        <div className="footer-container">
          <img src={'/playground_assets/logo.svg'} alt='logo' style={{ width: "400px", height: "200px", marginTop: "-70px", marginLeft: "-115px", marginBottom: "-70px" }} />

          <span className="footer-text01">
            <span>
              Building world-class digital solutions for firefighters.
            </span>
            <br></br>
          </span>
          <div className="footer-social-media">
            <span className="footer-text04 heading4">Follow us</span>
            <div className="footer-container1">
              <a href="https://www.linkedin.com/company/endurus-technologies/" target="_blank">
                <svg viewBox="0 0 1024 1024" className="footer-icon">
                  <path d="M384 384h177.106v90.782h2.532c24.64-44.194 84.958-90.782 174.842-90.782 186.946 0 221.52 116.376 221.52 267.734v308.266h-184.61v-273.278c0-65.184-1.334-149.026-96.028-149.026-96.148 0-110.82 70.986-110.82 144.292v278.012h-184.542v-576z"></path>
                  <path d="M64 384h192v576h-192v-576z"></path>
                  <path d="M256 224c0 53.019-42.981 96-96 96s-96-42.981-96-96c0-53.019 42.981-96 96-96s96 42.981 96 96z"></path>
                </svg>
              </a>
              <a href="https://twitter.com/endurus_tech" target="_blank">
                <svg viewBox="0 0 1024 1024" className="footer-icon4">
                  <path d="M1024 226.4c-37.6 16.8-78.2 28-120.6 33 43.4-26 76.6-67.2 92.4-116.2-40.6 24-85.6 41.6-133.4 51-38.4-40.8-93-66.2-153.4-66.2-116 0-210 94-210 210 0 16.4 1.8 32.4 5.4 47.8-174.6-8.8-329.4-92.4-433-219.6-18 31-28.4 67.2-28.4 105.6 0 72.8 37 137.2 93.4 174.8-34.4-1-66.8-10.6-95.2-26.2 0 0.8 0 1.8 0 2.6 0 101.8 72.4 186.8 168.6 206-17.6 4.8-36.2 7.4-55.4 7.4-13.6 0-26.6-1.4-39.6-3.8 26.8 83.4 104.4 144.2 196.2 146-72 56.4-162.4 90-261 90-17 0-33.6-1-50.2-3 93.2 59.8 203.6 94.4 322.2 94.4 386.4 0 597.8-320.2 597.8-597.8 0-9.2-0.2-18.2-0.6-27.2 41-29.4 76.6-66.4 104.8-108.6z"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div className="footer-container2">
          <span>
            <span className="heading4">Find Us</span>
            <br></br>
          </span>
          <span className="footer-text08">
            <span>4th Floor,</span>
            <br></br>
            <span>Silverstream House,</span>
            <br></br>
            <span>Fitzrovia,</span>
            <br></br>
            <span>London</span>
            <br></br>
          </span>
          <span>
            <span className="heading4">Contact Us</span>
            <br></br>
          </span>
          <span className="footer-text20">
            <span>info@endurustechnologies.com</span>
            <br></br>
          </span>
        </div>
      </div>
    </footer>
  )
}

Footer.defaultProps = {
  text: 'Endurus',
  text11: 'Endurus',
}

Footer.propTypes = {
  text: PropTypes.string,
  text11: PropTypes.string,
}

export default Footer
