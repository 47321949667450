import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { doc, addDoc, getFirestore, collection } from "firebase/firestore"
import { app } from "../../firebase-config"

import './index.css'

const InputContainer = (props: any) => {
  const [userEmail, setUserEmail] = useState<string>("")
  const [submitted, setSubmitted] = useState<boolean>(false)

  const db = getFirestore(app)

  const updateTextField = (e: any) => {
    setUserEmail(e.target.value)
    console.log(e.target.value)
  }

  const signupUserToWaitlist = async () => {
    // we don't check for duplicates because there's no point
    // much better to not waste that compute
    try {
      const email_re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (email_re.test(userEmail)) {
        await addDoc(collection(db, "waitlist_signups"), {
          "email": userEmail
        })
        console.log("successful addition")
        setSubmitted(true)
      }
      else {
        console.log("malformed email input")
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <form onSubmit={(e) => { e.preventDefault() }}>
      <div className="input-container-input-container">
        <div className="input-container-container input">
          <input
            onChange={updateTextField}
            type="email"
            placeholder={props.textinput_placeholder}
            className="input-container-input input"
          />
        </div>
        {!submitted &&
          <button type="submit" className="input-container-button button-primary button" onClick={() => signupUserToWaitlist()}>
            {props.button}
          </button>
        }
        {submitted && <button className="input-container-button button-primary button" style={{ backgroundColor: "#364570ff" }}>Thanks! We'll be in touch!</button>}
      </div>
    </form>
  )
}

InputContainer.defaultProps = {
  button: 'Stay in touch',
  textinput_placeholder: 'Your email address...',
}

InputContainer.propTypes = {
  button: PropTypes.string,
  textinput_placeholder: PropTypes.string,
}

export default InputContainer

