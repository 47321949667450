import React from 'react'

import { Helmet } from 'react-helmet'

import Navbar from '../../../components/navbar'
import InputContainer from '../../../components/input-container'
import Footer from '../../../components/footer'
import './index.css'

const Home = (props: any) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Endurus</title>
        <meta property="og:title" content="Endurus" />
      </Helmet>
      <Navbar {...{ PrimaryButtonText: "Client Login", PrimaryButtonPath: "/client-portal/" }}></Navbar>
      <main className="home-main">
        <div className="section-container">
          <div className="home-max-width max-content-container">
            <div className="home-content-container">
              <h1 className="home-text heading1">
                <span>We&apos;re on a mission to support </span>
                <span className="home-text2">
                  firefighters.
                </span>
              </h1>
              <div className="home-features-container">
                <span className="home-text3">
                  <span>
                    Interested?
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span className="home-text5">Sign up below</span>
                  <span> for future updates</span>
                  <span className="home-text7">.</span>
                  <br></br>
                </span>
              </div>
              <InputContainer></InputContainer>
            </div>
            <div className="home-image-container">
              <img
                alt="image"
                src="/playground_assets/firefighters.jpg"
                className="home-image"
              />
            </div>
          </div>
        </div>
      </main>
      <Footer></Footer>
    </div>
  )
}

export default Home
