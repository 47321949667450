import { getFunctions, httpsCallable } from "firebase/functions";
import { regions } from "../../constants/regions";
import { app } from "../../firebase-config";


export const pauseLivestream = async () => {
    //TODO:  we need to handle regions here? YES!
    const functions = getFunctions(app, "us-west1");
    const analysisAPI = httpsCallable(functions, 'pauseLivestreaming');
    return await analysisAPI({ params: { IP: "10.138.0.4" } })
        .then((result: any) => {
            return result
        })
        .catch((e) => console.log("pauseLivestreamError", e))
}