import { getStorage, ref, listAll, StorageReference, getDownloadURL, getBlob, getMetadata, FullMetadata, deleteObject } from "firebase/storage";
import { app } from "../firebase-config";
import { getUserOrg } from "./organization";
import { regionToBucketMapping } from "../constants/regions";
import { FileData } from "../components/data-mananger/data-manager-files";
import { getFunctions, httpsCallable } from "firebase/functions";

export interface getOrganizationStorageReferenceProps {
    path: string,
    filename: string
}

export const getOrganizationStorageReference = async (props: getOrganizationStorageReferenceProps) => {
    const org = await getUserOrg()
    if (org === undefined) {
        return undefined
    }

    const uoid: string = org.uoid
    const orgRegion: string = org.region

    //TODO: what if this returns null?
    const bucketURL = Object(regionToBucketMapping)[orgRegion]
    const pathURL = uoid + "/" + props.path + "/" + props.filename

    const storage = getStorage(app, bucketURL)
    // the folder name is the org uoid, so we immediately point into this
    return ref(storage, pathURL)
}


export interface getOrganizationStorageFilesInterface {
    path: string
}

export type ItemTuple = [StorageReference, FullMetadata]

export const getOrganizationStorageFiles = async (props: getOrganizationStorageFilesInterface) => {
    const org = await getUserOrg()
    if (org === undefined) {
        return undefined
    }

    const uoid: string = org.uoid
    const orgRegion: string = org.region

    //TODO: what if this returns null?
    const bucketURL = Object(regionToBucketMapping)[orgRegion]
    const pathURL = uoid + "/" + props.path

    const storage = getStorage(app, bucketURL)
    const listRef = ref(storage, pathURL)

    let items: StorageReference[] = [];
    await listAll(listRef)
        .then((res) => {
            res.prefixes.forEach((folderRef) => {
                // All the prefixes under listRef.
                // You may call listAll() recursively on them.
                console.log("Subfolder", folderRef.name)
            });
            res.items.forEach((item: StorageReference) => {
                // All the items under listRef.
                items.push(item)
            });
        }).catch((error) => {
            console.log(error)
            // Uh-oh, an error occurred!
        })

    const outTuples: ItemTuple[] = await Promise.all(items.map(async (item) => {
        const metadata = await getMetadata(item)
        return [item, metadata]
    }))

    return outTuples
}

export const deleteFile = async (file: StorageReference) => {

    //TODO: there should be backend protections against this, obviously
    // I kept this to single use because bulk delete terrifies me

    await deleteObject(file).then(() => {
        return true
    }).catch((error) => {
        console.log(error)
        return false
    });
    return true
}

export interface getDownloadFromItemsInterface {
    items: StorageReference[]
}

export const getDownloadFromItems = async (props: getDownloadFromItemsInterface) => {
    const org = await getUserOrg()
    if (org === undefined) {
        return undefined
    }

    const uoid: string = org.uoid
    const orgRegion: string = org.region

    const bucketURL = Object(regionToBucketMapping)[orgRegion]
    const storage = getStorage(app, bucketURL)

    for (const itemId in props.items) {
        const item = props.items[itemId]

        getDownloadURL(ref(storage, item.fullPath))
            .then((url) => {
                //TODO: is this the best way to do this?
                const xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.onload = (event) => {
                    const blob = xhr.response;
                    const blobURL = URL.createObjectURL(blob)
                    var link = document.createElement("a");
                    link.href = blobURL;
                    link.download = item.name;
                    document.body.appendChild(link);
                    link.click()
                    document.body.removeChild(link);
                };
                xhr.open('GET', url);
                xhr.send();
            })
            .catch((error) => {
                console.log(error)
            });
    }
}

export interface getMapFromStorageUsingRunIdInterface {
    urid: string,
    isLivestream?: boolean
}

export const getMapFromStorageUsingRunId = async (props: getMapFromStorageUsingRunIdInterface) => {
    // Take a unique run id, tries to find the output in storage, if it
    // succeeds returns HTML of the map, if it fails does nothing


    const org = await getUserOrg()
    if (org === undefined) {
        return undefined
    }

    const uoid: string = org.uoid
    const orgRegion: string = org.region

    const bucketURL = Object(regionToBucketMapping)[orgRegion]
    const storage = getStorage(app, bucketURL)

    let fullPath = bucketURL + "/" + uoid + "/analyses/" + props.urid + "/output/" + props.urid + "/map.html"

    if (props.isLivestream) {
        // an annoyingg restriction on gcp  copy means we need a different path
        fullPath = bucketURL + "/" + uoid + "/analyses/livestream_output/output/" + props.urid + "/map.html"
    }

    return await getBlob(ref(storage, fullPath)).then((blob) => {
        console.log(blob)
        return blob.text().then((text) => {
            return text
        })
    })
}

export interface getLatestFrameFromStorageUsingRunIdInterface {
    urid: string
}

export const getLatestFrameFromStorageUsingRunId = async (props: getLatestFrameFromStorageUsingRunIdInterface) => {
    // Take a unique run id, tries to find the output in storage, if it
    // succeeds returns HTML of the map, if it fails does nothing


    const org = await getUserOrg()
    if (org === undefined) {
        return undefined
    }

    const uoid: string = org.uoid
    const orgRegion: string = org.region

    const bucketURL = Object(regionToBucketMapping)[orgRegion]
    const storage = getStorage(app, bucketURL)

    const fullPath = bucketURL + "/" + uoid + "/analyses/livestream_output/output/" + props.urid + "/latest_frame.png"

    return await getDownloadURL(ref(storage, fullPath)).then((url) => {
        console.log(url)
        return url
    })
}

export const getAnalysisMetadataFromStorageUsingRunID = async (props: getMapFromStorageUsingRunIdInterface) => {
    // Take a unique run id, tries to find the output in storage, if it
    // succeeds returns metadata file, if it fails does nothing

    const org = await getUserOrg()
    if (org === undefined) {
        return undefined
    }

    const uoid: string = org.uoid
    const orgRegion: string = org.region

    const bucketURL = Object(regionToBucketMapping)[orgRegion]
    const storage = getStorage(app, bucketURL)

    var fullPath = bucketURL + "/" + uoid + "/analyses/" + props.urid + "/output/" + props.urid + "/metadata.json"

    if (props.isLivestream) {
        fullPath = bucketURL + "/" + uoid + "/analyses/livestream_output/output/" + props.urid + "/metadata.json"
    }

    return await getBlob(ref(storage, fullPath)).then((blob) => {
        console.log(blob)
        return blob.text().then((text) => {
            return JSON.parse(text)
        })
    })
}

export interface getFileReferenceFromStorageUsingRunIdInterface {
    urid: string,
    fileName: string,
    fileType?: string,
    livestream?: boolean
}

export const getFileReferenceFromStorageUsingRunId = async (props: getFileReferenceFromStorageUsingRunIdInterface) => {
    // Take a unique run id, tries to find the output in storage, if it
    // succeeds returns HTML of the map, if it fails does nothing
    const org = await getUserOrg()
    if (org === undefined) {
        return undefined
    }

    const uoid: string = org.uoid
    const orgRegion: string = org.region

    const bucketURL = Object(regionToBucketMapping)[orgRegion]
    const storage = getStorage(app, bucketURL)

    const fileType = props.fileType ?? ".json"

    var fullPath = bucketURL + "/" + uoid + "/analyses/" + props.urid + "/output/" + props.urid + "/" + props.fileName + fileType

    if (props.livestream) {
        fullPath = bucketURL + "/" + uoid + "/analyses/livestream_output/output/" + props.urid + "/" + props.fileName + fileType
    }
    return ref(storage, fullPath)

}

export interface moveFileIntoFolderProps {
    currentPath: string,
    destinationPath: string
}

export const moveFile = async (currentPath: string, destinationFolder: string) => {
    const org = await getUserOrg()
    if (org === undefined) {
        return undefined
    }

    const orgRegion: string = org.region
    const bucketURL = Object(regionToBucketMapping)[orgRegion]

    const fullDestinationPath = destinationFolder + "/" + currentPath.split("/").slice(-1)

    const functions = getFunctions(app, "us-west1")
    const moveFileAPI = httpsCallable(functions, `moveFile`)
    return await moveFileAPI({ params: { bucket: bucketURL, filePath: currentPath, destinationFilePath: fullDestinationPath } })
        .then((result) => {
            return result
        })
        .catch((e) => {
            console.log(e)
        })
}