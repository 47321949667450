import { Box, Card, CardActionArea, CardContent, CardMedia, Divider, Typography } from "@mui/material"
import { DataAnalyzerResultsMap } from "../../../../../../components/data-analyzer/data-analyzer-results/data-analyzer-results-map"
import IncidentPreview from "./incident-preview"
import { Link } from "react-router-dom"
import { borderRadius, width } from "@mui/system"
import { options } from "yargs"
import "./index.css"

export interface IncidentCardProps {
    incidentName: string,
    incidentCreatedTimestamp: number,
    incidentType: string,
    incidentLocation: string,
    incidentUIID: string
}

const previewMap = () => {
    let incidentLocation =""
    if (incidentLocation != ""){
        return (
            < IncidentPreview location={incidentLocation} />
        )
    }else{
        return (
            <img width="100%" height="100%" src="/assets/icons/map_preview2.png" />
        )
    }
}

export const IncidentCard = (props: IncidentCardProps) => {
    const date = new Date(props.incidentCreatedTimestamp)

    return (
        <Link className={"CardLink"} to={props.incidentUIID}>
            <Card sx={{height: "20vh", minHeight: "150px", maxHeight: "300px", borderRadius: "15px"}}>
                <CardMedia sx={{height: "50%", width: "100%", justifyContent: "center", alignItems: "center"}}>
                    {previewMap()}
                </CardMedia>
                <CardContent>
                    <Typography gutterBottom variant="body2" component="div">
                        {props.incidentName}
                    </Typography>
                    <Typography variant="caption" sx={{fontStyle: "italic"}}>
                        {new Intl.DateTimeFormat().format(date)}
                    </Typography>
                </CardContent>
            </Card>
        </Link >
    )
}