import { getDownloadFromItems, getFileReferenceFromStorageUsingRunId } from "../storage"


export const downloadMapGeojson = (urid: string, livestream?: boolean) => {
    if (urid) {
        getFileReferenceFromStorageUsingRunId({ urid: urid, fileName: "geojson_zipped", fileType: ".zip", livestream: livestream })
            .then((ref) => {
                if (ref) {
                    getDownloadFromItems({ items: [ref] })
                }
                else {
                    alert("Invalid storage configuration, please contact an administrator")
                }
            })
    }
}

export const downloadMapShapefile = (urid: string, livestream?: boolean) => {
    if (urid) {
        getFileReferenceFromStorageUsingRunId({ urid: urid, fileName: "shapefile_zipped", fileType: ".zip", livestream: livestream })
            .then((ref) => {
                if (ref) {
                    getDownloadFromItems({ items: [ref] })
                }
                else {
                    alert("Invalid storage configuration, please contact an administrator")
                }
            })
    }
}