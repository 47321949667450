export const regions =
    new Map()
        .set("EU", "europe-west1")
        .set("US", "us-central1")
        .set("dev", "us-west1")
        .set("EUdev", "europe-west1")

export const regionToBucketMapping = {
    "EU": process.env.REACT_APP_euStorageUrl,
    "US": process.env.REACT_APP_usStorageUrl,
    "dev": process.env.REACT_APP_usStorageUrl,
    "EUdev": process.env.REACT_APP_euStorageUrl
}