import { getAuth } from "firebase/auth"
import { collection, doc, getDocs, getFirestore, query, getDoc, addDoc, where } from "firebase/firestore"
import { app } from "../../firebase-config"
import { IncidentDataInterface } from "../../interfaces/IncidentDataInterface"
import { getUserOrg } from "../organization"
import { v4 as uuidv4 } from 'uuid';
import { LiteFileRef } from "../../../public/interfaces/LiteFileRef"
import { createFolder } from "../folder"

export interface CreateIncidentDataInterface {
    name: string,
    incidentType: string,
}

export const createIncident = async (props: CreateIncidentDataInterface) => {
    const db = getFirestore(app)
    const uuid = getAuth().currentUser?.uid
    const uoid = Object(await getUserOrg()).uoid

    //TODO: we need to make sure there's no conflict in the future
    const uiid = uuidv4()

    const incidents = collection(db, "incidents");

    if (uoid == undefined || uuid == undefined) {
        //TODO: noisier pls
        return
    }

    const fileRefs: LiteFileRef[] = []
    const analyses: string[] = []
    const createdDate = new Date().getTime()
    const lastModifiedDate = createdDate
    const location = ""

    let newIncident: IncidentDataInterface = { ...props, uiid, uoid, uuid, fileRefs, createdDate, lastModifiedDate, location, analyses }

    await createFolder("/", uiid).then(() => { }).catch((err) => alert(err))

    return await addDoc(incidents, newIncident).then((res) => {
    }).catch(
        e => {
            console.log(e)
        }
    )
}