import { Box, Container, Card, Button, Tooltip, Dialog, DialogContent, DialogActions, TextField, DialogTitle, CircularProgress } from '@mui/material';
import StorageIcon from '@mui/icons-material/Storage';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import AddIcon from '@mui/icons-material/Add';
import StopIcon from '@mui/icons-material/Stop';
import RestorePageIcon from '@mui/icons-material/RestorePage';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useEffect, useState } from 'react';
import { resumeLivestream } from '../../../../actions/livestreaming/resume'
import { pauseLivestream } from '../../../../actions/livestreaming/pause'
import { stopLivestream } from '../../../../actions/livestreaming/stop'
import { startLivestream } from '../../../../actions/livestreaming/start'
import { getLivestreamStatus } from '../../../../actions/livestreaming/status';
import { getOrgLivestreamInfo } from '../../../../actions/livestreaming/org-livestreaming-info';
import { DocumentData } from 'firebase/firestore';
import { ShowOnHover } from '../../../general/show-on-hover';
import { theme } from '../../../../constants/theme';

export interface LivestreamListenerManagerProps {
    urid: string | undefined,
    setUrid: Function
}


export const LivestreamListenerManager = (props: LivestreamListenerManagerProps) => {

    const [currentState, setCurrentState] = useState<"Stopped" | "Running" | "Paused">("Stopped")
    const [startTime, setStartTime] = useState<undefined | string>()
    const [instanceName, setInstanceName] = useState<undefined | string>()
    const [livestreamOrgInfo, setLivestreamOrgInfo] = useState<DocumentData | undefined>()

    const [userDefinedInstanceName, setUserDefinedInstanceName] = useState<string>("")
    const [openNewInstanceDialog, setOpenNewInstanceDialog] = useState<boolean>(false)

    const [blockClicks, setBlockClicks] = useState(false)

    const getCurrentState = () => {
        getLivestreamStatus().then((res) => {
            if (res.data.message.urid) {
                setCurrentState("Running");
            }
            props.setUrid(res.data.message.urid);
            setInstanceName(res.data.message.instance_name)
            setStartTime(res.data.message.start_time)
        })
    }

    // handles on/off
    const turnOn = () => { }
    const turnOff = () => { }

    // handles instances/when it's running
    //TODO: get this from current state
    const resume = () => {
        setBlockClicks(true)
        resumeLivestream().then((res) => {
            setCurrentState("Running"); console.log(res); setBlockClicks(false)
        })
    }
    const pause = () => {
        setBlockClicks(true);
        pauseLivestream().then((res) => {
            setCurrentState("Paused"); console.log(res); setBlockClicks(false)
        })
    }
    const start = () => {
        setBlockClicks(true)
        startLivestream({ ...{ instanceName: userDefinedInstanceName } }).then((res) => {
            setCurrentState("Running");
            console.log(res);
            props.setUrid(res.data.message.urid)
            setInstanceName(res.data.message.instance_name)
            setStartTime(res.data.message.start_time)
            setOpenNewInstanceDialog(false)
            setBlockClicks(false)
        })
    }
    const stop = () => {
        setBlockClicks(true)
        stopLivestream().then(() => { setCurrentState("Stopped"); setBlockClicks(false); setInstanceName(""); setStartTime(undefined) })
    }

    useEffect(() => {
        getOrgLivestreamInfo().then((res: any) => {
            if (res) {
                for (const vm in res.data.vms) {
                    //TODO: this will select the last vm, which we need to change in future to support multiple VMs
                    setLivestreamOrgInfo(res.data.vms[vm])
                }
            }
        });
        getCurrentState()
    }, [])


    return (
        <Card sx={{ height: "50%", width: "80%" }}>
            <Card sx={{ height: "5vh", minHeight: "5vh", flexDirection: "row", display: "flex", alignItems: "center" }}>
                <StorageIcon fontSize='medium' style={{ marginLeft: "3px", justifyContent: "center" }} />
                <h2 style={{ marginLeft: "10px" }}>{livestreamOrgInfo?.name}</h2>
                <div style={{ marginLeft: "auto" }}>
                    <Tooltip title={"View in feature server"}>
                        <Button disabled startIcon={<OpenInNewIcon />}></Button>
                    </Tooltip>
                    <Tooltip title={"Turn on/off"} >
                        <Button disabled onClick={() => getCurrentState()} startIcon={<PowerSettingsNewIcon />}></Button>
                    </Tooltip>
                </div>
            </Card>

            <Box sx={{ display: "flex", flexDirection: "column", height: "calc(100% - 5vh)" }}>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Box sx={{ padding: "20px" }}>
                        {/* <h4>VM Address: </h4><p style={{}}>{livestreamOrgInfo!.external_ip}:{livestreamOrgInfo!.port}</p> */}
                        <h4 style={{ minWidth: "8.5vw" }}>VM Address: </h4>
                        {livestreamOrgInfo && <ShowOnHover  {...{ textToDisplay: livestreamOrgInfo.external_ip + ":" + livestreamOrgInfo.port, copyToClipboard: true }} />}
                        <br></br>
                        <h4>Current instance: </h4><p>{instanceName}</p>
                    </Box>
                    <Box sx={{ padding: "20px", marginLeft: "20%" }}>
                        <h4>Status: </h4><p>{currentState}</p>
                        <br></br>
                        <h4>Start Time: </h4><p>{startTime}</p>
                    </Box>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-evenly", marginTop: "auto" }}>
                    {currentState == "Running" && !blockClicks &&
                        (<Tooltip title={"Pause"}>
                            <Button onClick={() => pause()} sx={{ width: "25%", borderRadius: "0px", borderColor: "gray", borderWidth: "1px" }} startIcon={<PauseIcon />}></Button>
                        </Tooltip>)
                    }
                    {currentState == "Paused" && !blockClicks &&
                        (<Tooltip title={"Resume"}>
                            <Button onClick={() => resume()} sx={{ width: "25%", borderRadius: "0px", borderColor: "gray", borderWidth: "1px" }} startIcon={<PlayArrowIcon />}></Button>
                        </Tooltip>)
                    }
                    {currentState != "Stopped" && !blockClicks &&
                        (<Tooltip title={"Stop running and save instance"}>
                            <Button onClick={() => stop()} sx={{ width: "25%", borderRadius: "0px", borderColor: "gray", borderWidth: "1px" }} startIcon={<StopIcon />}></Button>
                        </Tooltip>)
                    }
                    {currentState != "Stopped" && blockClicks &&
                        (<CircularProgress size={"1rem"} sx={{ marginBottom: "10px" }} />)
                    }
                    {currentState == "Stopped" &&
                        (<Tooltip title={"Create new instance"}>
                            <Button onClick={() => { setOpenNewInstanceDialog(true) }} sx={{ width: "25%", borderRadius: "0px", borderColor: "gray", borderWidth: "1px" }} startIcon={<AddIcon />}></Button>
                        </Tooltip>)
                    }
                    {currentState == "Stopped" &&
                        <Tooltip title={"Load from previous instance"}>
                            <Button disabled sx={{ width: "25%", borderRadius: "0px", borderColor: "gray", borderWidth: "1px" }} startIcon={<RestorePageIcon />}></Button>
                        </Tooltip>
                    }
                </Box>
            </Box>
            <Dialog open={openNewInstanceDialog} onClose={() => { setOpenNewInstanceDialog(false) }} aria-labelledby="form-dialog-title" >
                <DialogTitle>Create a new listener instance</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="instanceName"
                        label="Instance Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={userDefinedInstanceName}
                        onChange={e => {
                            setUserDefinedInstanceName(e.target.value)
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    {!blockClicks && <Button onClick={() => { setOpenNewInstanceDialog(false) }}>Cancel</Button>}
                    {!blockClicks && (<Button onClick={start}>Create</Button>)}
                    {blockClicks && (<div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <CircularProgress size={"1rem"} sx={{ color: theme.primary }} />
                    </div>)}
                </DialogActions>
            </Dialog>

        </Card >
    )
}