import { doc, addDoc, getFirestore, collection, query, where, getDocs, setDoc } from "firebase/firestore"
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { app } from "../../firebase-config"

export interface ProfileValues {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    defaultPayload?: string;
}

export const setProfileData = (values: ProfileValues) => {
    const db = getFirestore(app)
    const auth = getAuth().currentUser
    const uuid = auth?.uid

    const licences = collection(db, "user_settings");
    const q = query(licences, where("uuid", "==", uuid))

    return getDocs(q).then((res) => {
        if (res.docs.length > 1) {
            alert("Account configuration error, please contact an administrator")
        }
        const docRef = res.docs[0].ref
        setDoc(docRef, {
            first_name: values.firstName,
            last_name: values.lastName,
            email: values.email,
            phone: values.phone,
            default_payload: values.defaultPayload ?? "",
            uuid: uuid
        })
    })
}

export const getProfileData = async () => {
    const db = getFirestore(app)
    const auth = getAuth().currentUser
    const uuid = auth?.uid

    const licences = collection(db, "user_settings");
    const q = query(licences, where("uuid", "==", uuid))

    return await getDocs(q).then((res) => {
        if (res.docs.length > 1) {
            alert("Account configuration error, please contact an administrator")
        }
        return { data: res.docs[0].data(), email: auth?.email ?? '' }
    })
}