import { getFunctions, httpsCallable } from "firebase/functions";
import { app } from "../firebase-config";

export const getAddressFromLatLon = async (lat: number, lon: number) => {
    const functions = getFunctions(app, "us-west1")
    const geocodeApi = httpsCallable(functions, `reverseGeocode`)
    return await geocodeApi({ params: { lat: lat, lon: lon } })
        .then((result) => {
            //@ts-ignore
            const str: string = result.data.results[3].formatted_address
            return str
        })
        .catch((e) => {
            console.log(e)
        })
}