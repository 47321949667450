import ImageIcon from '@mui/icons-material/Image'
import MovieIcon from '@mui/icons-material/Movie'
import FolderIcon from '@mui/icons-material/Folder';
import FolderZipIcon from '@mui/icons-material/FolderZip';

export const getIconFromContentType = (contentType: string | undefined) => {
    if (contentType == undefined) {
        return <></>
    }
    if (contentType.includes("image")) {
        return (<ImageIcon fontSize='small' />)
    }

    if (contentType.includes("video") || contentType.includes("octet-stream")) {
        return (<MovieIcon fontSize='small' />)
    }

    if (contentType.includes("folder")) {
        return (<FolderIcon fontSize='small' htmlColor='#757575' />)
    }

    if (contentType.includes("zip")) {
        return (<FolderZipIcon fontSize='small' />)
    }



    return (<>{contentType}</>)
}