import { getAuth, signOut } from 'firebase/auth'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'


import "./index.css"


export interface NavbarProps {
  PrimaryButtonText: string,
  PrimaryButtonPath: string
}

const Navbar = (props: NavbarProps) => {

  const handleLogOut = () => {
    if (props.PrimaryButtonText == "Log Out") {
      signOut(getAuth())
    }
  }

  return (
    <div className="navbar-navbar-container">
      <header
        data-role="Header"
        className="navbar-max-width navigation-container"
      >
        <Link to="/">
          {/* <span className="navbar-text title">Endurus</span> */}
          <img className="logo" src={'/playground_assets/logo-transparency.png'} alt='logo' />
        </Link>
        <Link to={props.PrimaryButtonPath} onClick={() => handleLogOut()}>
          <button className="input-container-button button-primary button">{props.PrimaryButtonText}</button>
        </Link>
        <div
          data-type="BurgerMenu"
          className="navbar-burger-menu navigation-burger-menu"
        ></div>
      </header>
    </div>
  )
}
export default Navbar
