import { ChangeEvent, useEffect, useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField
} from '@mui/material';
import { getProfileData, setProfileData } from '../../../actions/settings/profile';
import { DocumentData } from 'firebase/firestore';
import { drone_types } from '../../../constants/drone_types';
import { theme } from '../../../constants/theme';

export const AccountProfileDetails = (props: any) => {
    const [settingsButtonState, setSettingsButtonState] = useState("Save Details")
    const [values, setValues] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        defaultPayload: ''
    });

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | SelectChangeEvent<string>) => {
        setValues({
            ...values,
            [event.target!.name]: event.target?.value
        });
    };


    const handleUpdateProfileData = () => {
        console.log(values)
        setProfileData(values).then(() => {
            setSettingsButtonState("Updated!")
        }).catch((e) => {
            setSettingsButtonState("Error")
        })
    }

    useEffect(() => {
        getProfileData().then((value) => {
            setValues({
                firstName: value.data.first_name,
                lastName: value.data.last_name,
                email: value.email,
                phone: value.data.phone,
                defaultPayload: value.data.default_payload
            })
        })
    }, [])

    return (
        <form
            autoComplete="off"
            noValidate
            {...props}
        >
            <Card>
                <CardHeader
                    subheader="The information can be edited"
                    title="Profile"
                />
                <Divider />
                <CardContent>
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                helperText="Please specify the first name"
                                label="First name"
                                name="firstName"
                                onChange={handleChange}
                                required
                                value={values.firstName}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                label="Last name"
                                name="lastName"
                                onChange={handleChange}
                                required
                                value={values.lastName}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                label="Email Address"
                                name="email"
                                onChange={handleChange}
                                value={values.email}
                                variant="outlined"
                                disabled
                            />
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                label="Phone Number"
                                name="phone"
                                onChange={handleChange}
                                type="number"
                                value={values.phone}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <InputLabel id="demo-simple-select-label">Default Payload Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="defaultPayload"
                                value={values.defaultPayload}
                                label="Default Payload Type"
                                onChange={handleChange}
                            >
                                {Array.from(drone_types.keys()).map((drone_type_key: any) => { return (< MenuItem sx={{ fontSize: theme.fontSizeMedium }} value={drone_types.get(drone_type_key)}>{drone_type_key}</MenuItem>) })}
                            </Select>
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        p: 2
                    }}
                >
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleUpdateProfileData}
                    >
                        {settingsButtonState}
                    </Button>
                </Box>
            </Card>
        </form>
    );
};