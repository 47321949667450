import { useContext } from 'react';
import { Box, MenuItem, MenuList, Popover, Typography } from '@mui/material';
import { getAuth, signOut } from 'firebase/auth';
import { Link } from 'react-router-dom';
import { downloadMapGeojson, downloadMapShapefile } from '../../../../../actions/maps/download-maps';

export interface ExportPopoverProps {
    anchorEl: any,
    onClose: Function,
    open: boolean,
    displayId: string
}

export const ExportPopover = (props: ExportPopoverProps) => {
    const { anchorEl, onClose, open, ...other } = props;

    return (
        <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: 'left',
                vertical: 'top'
            }}
            onClose={() => onClose()}
            open={open}
            PaperProps={{
                sx: { width: '200px' }
            }}
            {...other}
        >
            <Box
                sx={{
                    py: 1,
                    px: 2
                }}
            >
                <Typography>Export As...</Typography>
            </Box>
            <MenuList
                sx={{
                    borderTopColor: 'divider',
                    borderTopStyle: 'solid',
                    borderTopWidth: '1px',
                }}
            >
                <MenuItem onClick={() => { downloadMapGeojson(props.displayId) }}>
                    GeoJSON
                </MenuItem>
                <MenuItem onClick={() => { downloadMapShapefile(props.displayId) }}>
                    ArcGIS Shapefile
                </MenuItem>
            </MenuList>
        </Popover>
    );
};