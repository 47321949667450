import { useEffect, useRef, useState } from 'react';
import { AppBar, Avatar, Badge, Box, Container, IconButton, Toolbar, Tooltip } from '@mui/material'

import { Bell as BellIcon } from '../../icons/bell';
import { UserCircle as UserCircleIcon } from '../../icons/user-circle';
import { Users as UsersIcon } from '../../icons/users';
import { AccountPopover } from '../account-popover';

import { sideBarSize } from '../../constants/sizings';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { ArrowBack, PropaneSharp } from '@mui/icons-material';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

export const PortalNavbar = () => {
    const settingsRef = useRef(null);
    const [openAccountPopover, setOpenAccountPopover] = useState(false);

    const routesToTitles: Record<string, string> = {
        '/client-portal/': '',
        '/client-portal/incidents': 'Incidents',
        '/client-portal/livestream': 'Livestream',
        '/client-portal/data-manager': 'Data Manager',
        '/client-portal/settings': 'Settings',
        '/client-portal/invalid-licence': 'Invalid Licence'
    }

    const TitleManager = () => { 
        const location = useLocation()
        const [currentTitle, setCurrentTitle] = useState('')
        const [incidentView, setIncidentView] = useState(false)

        useEffect(() => {
            const route = location.pathname

            if(route.startsWith('/client-portal/incidents/')){
                setCurrentTitle('All Incidents')
                setIncidentView(true)
            }else{
                setCurrentTitle(routesToTitles[route] || 'Incidents')
                setIncidentView(false)
            }
        }, [location]);

        if(incidentView){
            return (
                <Link style={{display: 'flex', alignItems: 'center', color: 'grey', fontSize:'14px'}} to="/client-portal/incidents" key="incidents">
                    <ArrowBack sx={{fontSize:"14px"}}/>
                    <span style={{paddingLeft: "7px"}}>{currentTitle}</span>
                </Link>
            )
        } else {
            return (
                <h3>{currentTitle}</h3>
            )
        }
    }

    return (
        <>
            <Container sx={{display: "flex", px: 3, pt: 2}}>
                <Box sx={{height: "100%", display: 'flex', flexDirection: 'vertical', justifyContent: 'space-around'}}>{TitleManager()}</Box>
                <Box sx={{ flexGrow: 1 }} />
                {/* TODO */}
                {/* <Tooltip title="Organization">
                    <IconButton sx={{ ml: 1 }}>
                        <UsersIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Notifications">
                    <IconButton sx={{ ml: 1 }}>
                        <Badge
                            badgeContent={4}
                            variant="dot"
                            color="info"
                        >
                            <BellIcon fontSize="small" />
                        </Badge>
                    </IconButton>
                </Tooltip> */}
                <Box sx={{height: "100%", display: 'flex', flexDirection: 'vertical', justifyContent: 'space-around'}}>
                    <Avatar
                        onClick={() => setOpenAccountPopover(true)}
                        ref={settingsRef}
                        sx={{
                            cursor: 'pointer',
                            height: 25,
                            width: 25,
                        }}
                        src="/static/images/avatars/avatar_1.png"
                    />
                </Box>
            </Container>
            <AccountPopover
                anchorEl={settingsRef.current}
                open={openAccountPopover}
                onClose={() => setOpenAccountPopover(false)}
            />
        </>
    );
};