import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

export const Logo = styled((props: any) => {
    //@ts-ignore
    const { variant, ...other } = props;

    const color = variant === 'light' ? '#C1C4D6' : '#5048E5';

    return (
        <img width={42} height={42} src="/playground_assets/icon.png">
        </img>
    );
})``;

Logo.defaultProps = {
    variant: 'primary'
};

Logo.propTypes = {
    variant: PropTypes.oneOf(['light', 'primary'])
};