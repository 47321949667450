import { Box, Container } from "@mui/material"
import { DataManagerFiles } from "../../../../../../../components/data-mananger/data-manager-files"
import { DataManagerToolbar } from "../../../../../../../components/data-mananger/data-manager-toolbar"
import { useEffect, useState } from "react"
import { getUserOrg } from "../../../../../../../actions/organization"

export interface IncidentViewsDataManagerProps {
    uiid: string | undefined,
    openUploadModal: boolean,
    setOpenUploadModal: Function
}

export const IncidentViewsDataManager = (props: IncidentViewsDataManagerProps) => {

    const [refresh, refresher] = useState(false)

    return (
        <Container sx={{ display: "flex", flexDirection: "column", margin: "0!important", padding: "0!important", pt: "10px!important" }}>
            <Box sx={{ height: "calc(100vh - 130px)", display: "block", padding: "0!important" }}>
                <DataManagerFiles {...{ isDataManager: false, refresh: refresh, lockFolder: `/${props.uiid}`, setOpenUploadModal: props.setOpenUploadModal, isIncidentManager: true }} />
            </Box>
            <Box>
                <DataManagerToolbar {...{ refresh: refresh, refresher: refresher, currentFolderPath: `/${props.uiid}`, openUploadModal: props.openUploadModal, setOpenUploadModal: props.setOpenUploadModal }} />
            </Box>
        </Container>
    )
}