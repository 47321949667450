import { Box, Container } from "@mui/material"
import { useParams } from "react-router-dom"
import { IncidentToolbar } from "./incident-toolbar"
import { TabContext, TabPanel } from "@material-ui/lab"
import { useEffect, useState } from "react"
import { getIncidentFromDatabase, getIncidentsFromDatabase } from "../../../../../actions/incidents/get-incidents"
import { DocumentData } from "firebase/firestore"
import { IncidentViewsDataManager } from "./incident-views/incident-views-data-manager"
import { IncidentViewsMap } from "./incident-views/incident-views-map"

export const IncidentView = () => {

    const { uiid } = useParams()

    const [tabValue, setTabValue] = useState("0")
    const [incidentData, setIncidentData] = useState<void | DocumentData>()
    const [openUploadModal, setOpenUploadModal] = useState(false)
    const [openDataAnalyzerModal, setOpenDataAnalyzerModal] = useState(false)


    useEffect(() => {
        getIncidentFromDatabase(String(uiid)).then((incident) => {
            setIncidentData(incident)
        })
    }, [uiid])


    return (
        <>
            <title>
                Incident | Endurus
            </title>
            <Container
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 0
                }}
            >
                <TabContext value={tabValue}>
                    <IncidentToolbar {...{ setTabValue, incidentName: incidentData?.name, setOpenUploadModal, setOpenDataAnalyzerModal }} />
                    <TabPanel value={"0"} style={{ padding: 0, margin: 0 }}>
                        <IncidentViewsDataManager {...{ uiid: uiid, openUploadModal, setOpenUploadModal }} />
                    </TabPanel>
                    <TabPanel value={"1"} style={{ padding: 0, margin: 0 }}>
                        <IncidentViewsMap {...{ openDataAnalyzerModal, setOpenDataAnalyzerModal, navigator: setTabValue, uiid }} />
                    </TabPanel>
                </TabContext>
            </Container>
        </>
    )
}