import { Box, Container } from '@mui/material';
import { useState } from 'react';
import { DataManagerFiles } from '../../../../components/data-mananger/data-manager-files';
import { DataManagerToolbar } from '../../../../components/data-mananger/data-manager-toolbar';

export const DataManager = () => {

    const [refresh, refresher] = useState(false)
    const [currentFolder, setCurrentFolder] = useState<string | undefined>(undefined)
    const [openUploadModal, setOpenUploadModal] = useState(false)

    return (
        <>
            <title>
                Data Manager | Endurus
            </title>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 2
                }}

            >
                <Container sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
                    <Box sx={{ height: "85vh" }}>
                        <DataManagerFiles {...{ isDataManager: true, refresh: refresh, setCurrentFolder: setCurrentFolder, setOpenUploadModal: setOpenUploadModal, isIncidentManager: false }} />
                    </Box>
                    <Box >
                        <DataManagerToolbar {...{ refresh: refresh, refresher: refresher, currentFolderPath: currentFolder, openUploadModal: openUploadModal, setOpenUploadModal: setOpenUploadModal }} />
                    </Box>
                </Container>
            </Box>
        </>
    )
};