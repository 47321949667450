import { useCallback } from 'react'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { Link, Route, Routes, Navigate, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import Navbar from '../../../components/navbar'
import Footer from '../../../components/footer'

import './index.css'

const Login = () => {
    const navigate = useNavigate()

    const handleSubmit = useCallback(async (e: any) => {
        e.preventDefault()

        const { email, password } = e.target.elements
        const auth = getAuth()
        signInWithEmailAndPassword(auth, email.value, password.value)
            .then(() => {
                navigate("/client-portal/")
            })
            .catch(
                e => { alert("Invalid username or password") }
            )
    }, [])

    return (
        <div className="login-container">
            <Helmet>
                <title>Endurus</title>
                <meta property="og:title" content="Endurus Client Login" />
            </Helmet>
            <div className="login-login">
                <Navbar {...{ PrimaryButtonText: "Client Login", PrimaryButtonPath: "/login" }}></Navbar>
                <div className="login-container-1">
                    <form onSubmit={handleSubmit}>
                        <input name="email" placeholder="you@example.com" type="email" className="input-container-input input" style={{ marginBottom: "5px" }} />
                        <br></br>
                        <input name="password" placeholder="" type="password" className="input-container-input input" />
                        <br></br>
                        <br></br>
                        <button type="submit" className="input-container-button button-primary button">Login</button>
                    </form>
                    <br></br>
                    <br></br>
                    <p>Terms of Service</p>
                    <p><a href='https://www.privacypolicies.com/live/c791fcfe-3e7d-42c0-9292-5f195f460881' target='_blank'>Privacy Policy</a></p>
                    <br></br>
                    <Link to="/signup">
                        <span>No account? <span style={{ fontWeight: "bold" }}>Sign up here</span></span>
                    </Link>
                    <br></br>
                    <Link to="/reset-password">
                        <span>Forgot your password? <span style={{ fontWeight: "bold" }}>Reset it here</span></span>
                    </Link>
                </div>
                <Footer ></Footer>
            </div>
        </div>
    )
}

export default Login