import { theme } from "../constants/theme"
import {
    Avatar,
    Tooltip
} from '@mui/material';
import { getFunctions, httpsCallable } from "firebase/functions";
import { app } from "../firebase-config";
import { regions } from "../constants/regions";
import { RunProgressWithLabel } from '../components/data-analyzer/data-analyzer-results/data-analyzer-results-info/data-analyzer-results-info-progress-bar'
import { useEffect, useState } from "react";

export const getStatusBadge = (status: string, time: Date, urid: string) => {
    let color;
    // 30 mins in ms
    const expiryThreshold = 1800 * 1000;
    //TODO: GLOBAL ENUM!
    if (status == "Start") {
        if ((new Date().getTime() - time.getTime()) > expiryThreshold) {
            // considered "dead" or "lost"
            color = "gray"
            status = "Lost"
        }
        else {
            color = theme.inprogress
            status = "Running"
            return (
                <RunProgressWithLabel urid={urid} color={"warning"}></RunProgressWithLabel>
            )
        }
    }
    else if (status == "Complete") {
        color = theme.complete
    }
    else if (status == "Error") {
        color = "red"
    }
    else {
        color = "red"
    }
    return (
        <Tooltip title={status}>
            <Avatar sx={{ backgroundColor: color, width: "10px", height: "10px", color: "transparent" }} >
            </Avatar>
        </Tooltip>
    )
}

export const getDataAnalysis = async (logSetter: Function) => {
    //TODO:  we need to handle regions here? YES!
    const functions = getFunctions(app, "us-west1");
    const analysisAPI = httpsCallable(functions, 'getAnalyses');
    analysisAPI({ params: {} })
        .then((result: any) => {
            logSetter([...result.data[0]])
        })
        .catch((e) => console.log(e))
}

export const getDataAnalysisFromUrids = async (logSetter: Function, urids: string[]) => {
    console.log(urids, "urids")
    const functions = getFunctions(app, "us-west1");
    const analysisAPI = httpsCallable(functions, 'getAnalysesFromUrids');
    analysisAPI({ params: { urids: urids } })
        .then((result: any) => {
            logSetter([...result.data[0]])
        })
        .catch((e) => console.log(e))
}


export const getAnalysisAPIFromOrgRegion = (region: string) => {

    const functions = getFunctions(app, regions.get(region))

    let funcName;
    if (region == "dev") {
        funcName = ""
    }
    else {
        funcName = region
    }

    console.log(region, `runAnalysis${funcName}`)

    return httpsCallable(functions, `runAnalysis${funcName}`)
}