import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    TextField,
    DialogActions
} from '@mui/material';
import { useEffect } from 'react';
import { getRunFromDatabase, logRunToDatabase } from '../../../../actions/runs';
import { getUserOrgRegion } from '../../../../actions/organization';
import { getAnalysisAPIFromOrgRegion } from '../../../../actions/data-analyzer';

export interface ResultInfoInterface {
    isOpen: boolean,
    setIsOpen: Function,
    currentInfoItem: string | undefined,
    status: string | undefined,
    urid: string | undefined,
    setLoiter?: Function
}

export const DataAnalyzerResultInfo = (props: ResultInfoInterface) => {

    const displayFilePaths = (filePaths: string) => {
        return filePaths.split(";").map((path: string) => {
            console.log(path.split("/").slice(-1)[0])
            return (
                <p>{path.split("/").slice(-1)[0]}</p>
            )
        })
    }

    const retryRun = () => {
        if (props.urid) {
            getRunFromDatabase(props.urid).then(async (res) => {
                if (res) {
                    console.log(res);
                    if (props.setLoiter) {
                        props.setLoiter({ name: res.name, urid: props.urid! })
                    }
                    const region = await getUserOrgRegion()
                    const analysisAPI = getAnalysisAPIFromOrgRegion(region)
                    analysisAPI({ params: { files: res.fileRefs, name: res.name + " Retry", droneType: res.droneType } })
                        .then((result) => {
                            console.log(result)
                            logRunToDatabase({ fileRefs: res.fileRefs, name: res.name + " Retry", droneType: res.droneType, urid: props.urid!, startTime: (new Date()).getTime().toString() })
                        })
                        .catch((e) => { console.log(e) })
                }
            }).catch((e) => { console.log(e) })
        }
        props.setIsOpen(false)
        return
    }

    return (
        <Dialog open={props.isOpen}>
            {(props.status !== undefined) && (
                <>
                    {(props.status == "Error") && (
                        <>
                            <DialogTitle>Error Information</DialogTitle>
                            <DialogContent>
                                {props.currentInfoItem !== undefined && (
                                    // @ts-ignore
                                    <p>{JSON.parse(props.currentInfoItem)["error"]}</p>
                                )}
                            </DialogContent>
                        </>
                    )}
                    {(props.status == "Complete") && (
                        <>
                            <DialogTitle>Input Files</DialogTitle>
                            <DialogContent>
                                {props.currentInfoItem !== undefined && (
                                    // @ts-ignore
                                    <p>{displayFilePaths(JSON.parse(props.currentInfoItem)["filePaths"])}</p>
                                )}
                            </DialogContent>
                        </>
                    )}
                </>
            )}
            <DialogActions>
                <Button onClick={() => { retryRun() }}>Retry</Button>
                <Button onClick={() => props.setIsOpen(false)}>Cancel</Button>
            </DialogActions>
        </Dialog>
    )
}
