import { getAuth } from "firebase/auth"
import { collection, doc, getDocs, getFirestore, query, where } from "firebase/firestore"
import { app } from "../firebase-config"

//TODO: this duplicates the below function too much
export const getUserOrgName = async () => {
    const db = getFirestore(app)
    const uuid = getAuth().currentUser?.uid

    const orgs = collection(db, "organizations");
    const q = query(orgs, where("users", "array-contains", uuid))

    return await getDocs(q).then((res) => {
        if (res.docs.length == 0) {
            return "No organisation"
        }
        else if (res.docs.length == 1) {
            return res.docs[0].data().name
        }
        else {
            return "Multi-org Error"
        }
    })
}

export const getUserOrgRegion = async () => {
    const db = getFirestore(app)
    const uuid = getAuth().currentUser?.uid

    const orgs = collection(db, "organizations");
    const q = query(orgs, where("users", "array-contains", uuid))

    return await getDocs(q).then((res) => {
        if (res.docs.length == 0) {
            return "No organisation"
        }
        else if (res.docs.length == 1) {
            return res.docs[0].data().region
        }
        else {
            return "Multi-org Error"
        }
    })
}

export const getUserOrg = async () => {
    const db = getFirestore(app)
    const uuid = getAuth().currentUser?.uid

    const orgs = collection(db, "organizations");
    const q = query(orgs, where("users", "array-contains", uuid))

    return await getDocs(q).then((res) => {
        if (res.docs.length == 0) {
            return undefined
        }
        else if (res.docs.length == 1) {
            return res.docs[0].data()
        }
        else {
            return undefined
        }
    })
}