import {
    Box,
    Button,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import LinearProgress from '@mui/material/LinearProgress'
import PlayIcon from '@mui/icons-material/PlayArrow'
import PauseIcon from '@mui/icons-material/Pause'
import UploadIcon from '@mui/icons-material/Upload'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { theme } from '../../../constants/theme';
import { getOrganizationStorageReference } from '../../../actions/storage';
import { getDownloadURL, uploadBytes, uploadBytesResumable, UploadTask, UploadTaskSnapshot } from 'firebase/storage';
import { useEffect, useRef, useState } from 'react';
import { useDropzone } from "react-dropzone";
import { useNavigate } from 'react-router-dom';
import { roundToTwo } from '../../../actions/util';


interface ProgressTracker {
    fileName: string,
    progress: number
}

export interface DataManagerToolbarProps {
    refresher: Function,
    refresh: boolean,
    currentFolderPath: string | undefined,
    openUploadModal: boolean,
    setOpenUploadModal: Function
}

export const DataManagerToolbar = (props: DataManagerToolbarProps) => {
    const [uploadState, setUploadState] = useState<string | undefined>()
    const [uploadProgress, setUploadProgress] = useState<ProgressTracker[]>([])
    const [uploadManager, setUploadManager] = useState<UploadTask | undefined>()
    const [uploadFileName, setUploadFileName] = useState<string | undefined>()
    const [uploadCompleted, setUploadCompleted] = useState<boolean>(false)
    const [currentFileNumber, setCurrentFileNumber] = useState<string | undefined>()

    let { getRootProps, getInputProps, acceptedFiles } = useDropzone({});
    const navigate = useNavigate()

    // used to auto-scroll to bottom of uploads
    const uploadEndRef = useRef(null)
    const scrollToBottom = () => {
        if (uploadEndRef.current != null) {
            //@ts-ignore
            uploadEndRef.current.scrollIntoView({ behavior: "smooth" })
        }
    }

    useEffect(() => {
        scrollToBottom()
    }, [uploadProgress]);

    const handleFileSubmit = async (e: any) => {

        const localProgressTracker: ProgressTracker[] = []

        for (const i in acceptedFiles) {
            setCurrentFileNumber(i)
            const progress: ProgressTracker | undefined = await upload(acceptedFiles[i], localProgressTracker)
            if (progress) {
                localProgressTracker.push(progress)
            }
        }

        setUploadManager(undefined)
        setUploadState(undefined)
        setUploadProgress([])
        setUploadFileName(undefined)
        setUploadCompleted(true)
        props.refresher(!props.refresh)
    }

    const upload = async (file: File, progressTracker: ProgressTracker[]) => {
        let path = "core/"
        if (props.currentFolderPath !== undefined) {
            path = "core" + props.currentFolderPath
            console.log("UPLOADING TO: ", path)
        }
        const storageRef = await getOrganizationStorageReference({ path: path, filename: file.name })
        if (storageRef === undefined) {
            return
        }
        const uploadTask = uploadBytesResumable(storageRef, file)

        setUploadManager(uploadTask)
        setUploadState('running')

        setUploadFileName(file.name)
        setUploadProgress([...progressTracker, { fileName: file.name, progress: 0 }])

        uploadTask.on('state_changed',
            (snapshot: UploadTaskSnapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setUploadState(snapshot.state)
                setUploadProgress([...progressTracker, { fileName: file.name, progress: progress }])
            }),
            (error: Error) => {
                alert(error)
            },
            () => {
            }
        await uploadTask
        return { fileName: file.name, progress: 100 }
    }


    const getFiles = () => {
        let totalSize = 0
        const list = acceptedFiles.map((file) => {
            totalSize += file.size
            return (
                <li key={file.name} style={{ fontSize: "small", fontFamily: "helvetica" }}>
                    {file.name}
                </li>
            )
        })
        totalSize = roundToTwo(totalSize * 0.000001)
        return [<li style={{ fontWeight: "bold", fontFamily: "helvetica" }}>Total Upload Size: {totalSize} MB</li>, ...list]
    };

    return (
        <Dialog fullWidth={true} maxWidth={"md"} open={props.openUploadModal} >
            <DialogTitle>Upload</DialogTitle>
            <DialogContent sx={{ }}>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap'
                    }}
                >
                    {/* <Box sx={{ maxWidth: 500 }}>
                    <TextField
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon fontSize="small" />
                                </InputAdornment>
                            )
                        }}
                        placeholder="Search data"
                        variant="outlined"
                    />
                </Box> */}
                    <Card sx={{ border: "dashed", borderColor: theme.primary, width: "100%", padding: "15px" }} >
                        {acceptedFiles.length == 0 && !uploadState && !uploadCompleted &&
                            (
                                <div {...getRootProps({ className: "dropzone" })} style={{ width: "100%", minHeight: "20vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                    <input {...getInputProps()} />
                                    <p className="dropzone-content">Click here or drag to add files</p>
                                    <br></br>
                                    <AddIcon fontSize='large'></AddIcon>
                                </div>
                            )
                        }
                        {acceptedFiles.length > 0 && !uploadState && !uploadCompleted &&
                            (
                                <Box sx={{ display: "flex", flexDirection: "row", height: "100%" }}>

                                    <Box sx={{ width: "50%", overflowY: "scroll", mt: "1vh", ml: "1vh" }}>
                                        <ul>{getFiles()}</ul>
                                    </Box>

                                    <Box sx={{ width: "50%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                        <Button {...getRootProps({ className: "dropzone" })}
                                            variant="contained"
                                            startIcon={(<ChangeCircleIcon fontSize="small" />)}
                                            sx={{
                                                color: theme.secondary,
                                                backgroundColor: "white",
                                                width: "50%",
                                                '&:hover': {
                                                    backgroundColor: theme.bigred,
                                                    color: theme.textprimary
                                                },
                                            }}>
                                            <input {...getInputProps()} />
                                            Change
                                        </Button>
                                        <Button
                                            variant="contained"
                                            startIcon={(<UploadIcon fontSize="small" />)}
                                            component="span"
                                            sx={{
                                                mt: "1vh", color: theme.secondary,
                                                backgroundColor: "white",
                                                width: "50%",
                                                '&:hover': {
                                                    backgroundColor: theme.bigred,
                                                    color: theme.textprimary
                                                },
                                            }}
                                            onClick={handleFileSubmit}
                                        >
                                            Submit
                                        </Button>
                                    </Box>
                                </Box>
                            )
                        }
                        {uploadProgress.length == 0 && uploadCompleted && (
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: "100%" }}>
                                <p>Successfully uploaded</p>
                                <br></br>
                                <CheckCircleIcon color="success" />
                            </Box>
                        )}
                        {uploadProgress.length > 0 && (
                            <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
                                <h4>Uploading file {Number(currentFileNumber) + 1} of {acceptedFiles.length}</h4>
                                <Card sx={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "center", height: "20%" }}>
                                    <Button sx={{ color: theme.primary }} startIcon={<PauseIcon />} disabled={uploadManager?.snapshot.state == 'paused'} onClick={() => uploadManager?.pause()}></Button>
                                    <Button sx={{ color: theme.primary }} startIcon={<PlayIcon />} disabled={uploadManager?.snapshot.state == 'running'} onClick={() => uploadManager?.resume()}></Button>
                                    {/* <Button startIcon={<CancelIcon />} onClick={() => uploadManager?.cancel()}></Button> */}
                                </Card>
                                <Box sx={{ overflowY: "scroll", height: "80%" }}>
                                    {uploadProgress.map((progressTracker: ProgressTracker) => {
                                        return (
                                            <div style={{ marginBottom: "2vh" }}>
                                                <p style={{ fontFamily: "helvetica", fontSize: "small" }}>{progressTracker.fileName}: {roundToTwo(progressTracker.progress)}%</p>
                                                <LinearProgress variant="determinate" color="success" value={progressTracker.progress} />
                                            </div>
                                        )
                                    })}
                                    <div ref={uploadEndRef}></div>
                                </Box>
                            </Box>
                        )}
                    </Card>
                    {/* <Box sx={{ width: "30%", height: "100%", justifyContent: "right", alignItems: "end", display: "flex" }}>
                <Button onClick={() => navigate("/client-portal/data-analyzer")}
                    sx={{
                        width: "50%",
                        height: "50%",
                        backgroundColor: theme.primary,
                        color: theme.textprimary,
                        '&:hover': {
                            backgroundColor: theme.secondary
                        },
                    }}>
                    Analyze Data
                    <ArrowForwardIcon />
                </Button>
            </Box> */}
                </Box >
            </DialogContent>
            <DialogActions>
                {uploadProgress.length == 0 && uploadCompleted && (<Button onClick={() => { setUploadCompleted(false); acceptedFiles = [] }}>Upload Again</Button>)}
                {uploadProgress.length == 0 && uploadCompleted && (<Button onClick={() => { props.setOpenUploadModal(false); setUploadCompleted(false); acceptedFiles = [] }}>Close</Button>)}
                {!uploadCompleted && (<Button onClick={() => { props.setOpenUploadModal(false) }}>Cancel</Button>)}
            </DialogActions>
        </Dialog >
    )
};