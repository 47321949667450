// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_fireBaseAPIKey,
  authDomain: "endurus-site.firebaseapp.com",
  projectId: "endurus-site",
  storageBucket: "endurus-site.appspot.com",
  messagingSenderId: "447461571050",
  appId: "1:447461571050:web:afbaf6ddea67d2cbc99a93",
  measurementId: "G-DS125GLK2W"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);