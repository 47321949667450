import { theme } from '../../../../constants/theme';
import { LivestreamSetup } from '../../../../components/livestream/livestream-setup';
import { Tabs, Tab, Box, Container } from '@mui/material';
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext'
import TabList from '@material-ui/lab/TabList'
import { useState } from 'react';
import { LivestreamResults } from '../../../../components/livestream/livestream-results';

export const Livestream = () => {
    const [tabValue, setTabValue] = useState("0")
    const handleChange = () => {

    }
    return (
        <>
            <title>
                Livestream | Endurus
            </title>
            <TabContext value={tabValue} >
                <Box
                    component="main"
                    sx={{
                        py: 1,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                        fontSize: theme.fontSizeMedium
                    }}
                >
                    <TabList onChange={(event: any, value: string) => setTabValue(value)} variant={"standard"} TabIndicatorProps={{ style: { display: "none" } }}>
                        <Tab value={"0"} label="Current" disableRipple={true} />
                        {/* <Tab value={"1"} label="Runs" /> */}
                        <Tab value={"1"} label="Historical" disableRipple={true} />
                    </TabList>
                </Box>
                <TabPanel value={"0"} >
                    <LivestreamSetup />
                </TabPanel>
                <TabPanel value={"1"} >
                    <LivestreamResults />
                </TabPanel>
            </TabContext>
        </>
    )
};