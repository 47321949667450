import { createTheme } from '@material-ui/core/styles';

export const theme = {
    primary: '#595959',
    secondary: '#7c909b',
    textprimary: '#ffffff',
    inprogress: '#fcba03',
    complete: '#38c708',
    error: '',
    bigred: "#ef4e20ff",
    rowHeight: "20px",
    fontSizeMedium: "14px",
    fontSizeSmall: "10px"
};