import { Link, Route, Routes, Navigate, useNavigate } from 'react-router-dom'

import { Box, Grid } from '@mui/material'
import { Helmet } from 'react-helmet'
import { useEffect, useState } from 'react';

import { PortalSidebar } from '../../../components/portal-sidebar'
import { ClientHome } from './client-home';
import { DataManager } from './data-manager';
import { PortalNavbar } from '../../../components/portal-navbar';
import { Settings } from './settings';
import { getLicenceTypes, isUserLicenced } from '../../../actions/licencing';
import { sideBarSize } from '../../../constants/sizings';
import { DeviceManager } from './device-manager';
import { Incidents } from './incidents';
import { Livestream } from './livestream';
import { InvalidLicence } from './invalid-licence';
import { IncidentView } from './incidents/incident';

const ClientPortal = () => {
    const [isLicenced, setIsLicenced] = useState(false)
    const [licenceTypes, setLicenceTypes] = useState([])

    useEffect(() => {
        isUserLicenced().then((licenceState) => {
            setIsLicenced(licenceState)
        })
        getLicenceTypes().then((licenceTypes) => {
            setLicenceTypes(licenceTypes)
        })
    }, [])

    return (
        <div className="portal-container">
            <Helmet>
                <title>Endurus</title>
                <meta property="og:title" content="Endurus Client Portal" />
            </Helmet>
            <Box display={'flex'} >
                <PortalSidebar />
                <Box display={"flex"} flex={1} flexDirection={"column"}>
                    <PortalNavbar />
                    <Routes>
                        {/* ClientHome refers back to incidents page, useEffect (with license check) function only works after initial render in home, still some unexpected behavior */}
                        <Route element={<ClientHome />} path="/" />
                        <Route element={<DataManager />} path="/data-manager" />
                        <Route element={<Settings />} path="/settings" />
                        <Route element={<InvalidLicence />} path="/invalid-licence" />

                        <Route
                            element={
                                <LicencedRoute licence={{ isLicenced: isLicenced }}>
                                    <Incidents />
                                </LicencedRoute>
                            }
                            path="/incidents"
                        />
                        <Route path={"/incidents/:uiid"} element={<IncidentView />}></Route>
                        <Route
                            element={
                                <LicencedRoute licence={{ isLicenced: isLicenced }}>
                                    <DeviceManager />
                                </LicencedRoute>
                            }
                            path="/device-manager"
                        />
                        <Route
                            element={
                                <LicencedRoute licence={{ isLicenced: isLicenced, types: licenceTypes, requiredType: "livestream" }}>
                                    <Livestream />
                                </LicencedRoute>
                            }
                            path="/livestream"
                        />
                    </Routes>
                </Box>
            </Box>
        </div >
    )
}

//@ts-ignore
const LicencedRoute = ({ licence: { isLicenced, types = [""], requiredType = "" }, children }) => {
    let hasRequiredTypes = true;
    if (requiredType != "") {
        hasRequiredTypes = false;
        if (types.includes(requiredType)) {
            hasRequiredTypes = true;
        }
    }
    //TODO: redirect to a page that says "you need to have a licence"
    return (isLicenced && hasRequiredTypes) ? children : <Navigate to="/client-portal/invalid-licence" />;
};


export default ClientPortal