import {
    TableCell,
    Button,
    Tooltip,
    Checkbox
} from '@mui/material';
import { StorageReference } from 'firebase/storage';
import { getIconFromContentType } from '../../../../actions/icons';
import { FileData, StyledTableRow } from '..'
import { theme } from '../../../../constants/theme';
import { roundToTwo } from '../../../../actions/util';
import DownloadIcon from '@mui/icons-material/Download'
import PsychologyIcon from '@mui/icons-material/Psychology'
import DeleteIcon from '@mui/icons-material/Delete'
import { useNavigate } from 'react-router-dom';
import { useDrag } from 'react-dnd';
import useMergedRef from '@react-hook/merged-ref'
import { moveFile } from '../../../../actions/storage';
import { useEffect } from 'react';


export interface FolderRowComponentProps {
    storageRef: StorageReference,
    metadata: FileData,
    handleDirChange: Function,
    rowHeightRef: React.MutableRefObject<null>,
    isDataManager: boolean,
    isIncidentManager: boolean,
    handleOpenFolderDeletePromt: Function,
    selectedFileIds: string[],
    handleSelectOne: Function,
    downloadItems: Function,
    deleteItem: Function,
    loadFiles: Function
}

const FileRowComponent = (props: FolderRowComponentProps) => {
    const navigator = useNavigate()

    interface DropResult {
        name: string
    }

    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'box',
        item: { name: props.storageRef.fullPath },
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult<DropResult>()
            if (item && dropResult) {
                moveFile(props.storageRef.fullPath, dropResult.name).then(() => { props.loadFiles() })
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            handlerId: monitor.getHandlerId(),
        }),
    }), [props])

    return (
        <StyledTableRow ref={useMergedRef(props.rowHeightRef, drag)} key={props.storageRef.name} selected={props.selectedFileIds.indexOf(props.storageRef.name) !== -1}>
            {!props.isDataManager &&
                <TableCell padding="checkbox">
                    <Checkbox
                        value="false"
                        checked={props.selectedFileIds.indexOf(props.storageRef.name) !== -1}
                        onChange={(e) => { props.handleSelectOne(e, props.storageRef.name) }}
                        sx={{ paddingLeft: "0px!important" }}
                    />
                </TableCell>
            }
            {props.isDataManager &&
                <TableCell>
                    <div style={{ overflow: "hidden", height: theme.rowHeight, maxWidth: "20px", width: "20px", margin: "0px", padding: "0px" }}>
                        {getIconFromContentType(props.metadata.contentType)}
                    </div>
                </TableCell>
            }
            <TableCell ref={drag} sx={{ width: "30%", maxWidth: "30%", margin: "0px" }}>
                <Tooltip title={props.storageRef.name}>
                    <div style={{ display: "flex" }}>
                        <div style={{ overflow: "hidden", height: "20px", width: "100%", maxWidth: "100%", textOverflow: "ellipsis", whiteSpace: "nowrap", margin: "0px!important" }}>
                            {props.storageRef.name}
                        </div>
                    </div>
                </Tooltip>
            </TableCell>
            <TableCell sx={{ padding: "0px" }}>
                {props.metadata.modDate}
            </TableCell>
            {props.isDataManager &&
                <TableCell>{roundToTwo(props.metadata.size! * 0.000001)} MB</TableCell>
            }
            {/* MATCHES LOCATION <TableCell></TableCell> */}
            {(props.isDataManager || props.isIncidentManager) &&
                <TableCell sx={{ paddingRight: "0px", paddingLeft: "10px" }}>
                    <Tooltip title="Download">
                        <Button sx={{ minWidth: "30px", width: "30px", paddingTop: "0px!important", paddingBottom: "0px!important", color: theme.secondary }} startIcon={(<DownloadIcon fontSize="small" />)} onClick={() => props.downloadItems([props.storageRef])} />
                    </Tooltip>
                    <Tooltip title="Delete">
                        <Button sx={{ minWidth: "30px", width: "30px", paddingTop: "0px!important", paddingBottom: "0px!important", color: theme.secondary }} startIcon={(<DeleteIcon fontSize="small" />)} onClick={async () => { props.deleteItem(props.storageRef) }} />
                    </Tooltip>
                    {props.isDataManager && (
                        <Tooltip title="Analyze">
                            <Button sx={{ minWidth: "30px", width: "30px", paddingTop: "0px!important", paddingBottom: "0px!important", color: theme.secondary }} startIcon={(<PsychologyIcon fontSize="small" />)} onClick={async () => { navigator("/client-portal/data-analyzer") }} />
                        </Tooltip>
                    )}
                </TableCell>
            }
        </StyledTableRow>
    )
}

export default FileRowComponent