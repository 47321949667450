import { Box, Container, Grid, Typography } from '@mui/material';
import { AccountProfile } from '../../../../components/account/account-profile';
import { AccountProfileDetails } from '../../../../components/account/account-profile-details';
import { SettingsPassword } from '../../../../components/settings/settings-password';

export const Settings = () => (
    <>
        <title>
            Settings | Endurus
        </title>
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 8
            }}
        >
            <Container maxWidth="lg">
                <Grid
                    container
                    spacing={3}
                >
                    <Grid
                        item
                        lg={12}
                        md={6}
                        xs={12}
                    >
                        <AccountProfileDetails />
                    </Grid>
                    <Grid
                        item
                        lg={12}
                        md={6}
                        xs={12}
                    >
                        <SettingsPassword />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    </>
);