export const drone_types =
    new Map()
        .set("DJI Advanced Enterprise 2 Thermal", "dji_advanced_enterprise_2_thermal")
        .set("DJI Mavic 3 Thermal", "dji_mavic_3_thermal")
        .set("DJI Mavic 2 Thermal", "dji_mavic_2_thermal")
        .set("DJI Matrice Thermal", "dji_matrice_thermal")
        .set("DJI Zenmuse XT Thermal", "dji_xt")
        .set("DJI Zenmuse XT2 Thermal", "dji_xt2")
        .set("DJI Zenmuse H20 Thermal", "dji_zh20t")
        .set("Trilium Engineering HD Series", "l3_harris")
        // .set("DJI RGB", "dji_sdk_v4")
        .set("Other KLV-encoded Payload", "odf_unknown_payload")
        .set("Shamrock Spectrograph", "shamrock_spectrograph")