import React, { useState } from 'react'
import { BrowserRouter, BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { getAuth, onAuthStateChanged } from "firebase/auth"


import './style.css'
import Home from './views/authless/home'
import Login from './views/authless/login'
import ClientPortal from './views/auth/client-portal'
import SignUp from './views/authless/signup'
import ResetPassword from './views/authless/reset-password'
import NotFound from './views/authless/not-found'

export const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false)

    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
        if (user) {
            setIsAuthenticated(true)
        } else {
            setIsAuthenticated(false)
        }
    });


    return (
        <React.StrictMode>
            <BrowserRouter>
                <Routes>
                    <Route element={<Home />} path="/" />
                    <Route element={<Login />} path="/login" />
                    <Route element={<SignUp />} path="/signup" ></Route>
                    <Route element={<ResetPassword />} path="/reset-password" ></Route>
                    <Route
                        element={
                            <PrivateRoute auth={{ isAuthenticated: isAuthenticated }}>
                                <ClientPortal />
                            </PrivateRoute>
                        }
                        path="/client-portal/*"
                    />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </BrowserRouter>
        </React.StrictMode>
    )
}

//@ts-ignore
const PrivateRoute = ({ auth: { isAuthenticated }, children }) => {
    return isAuthenticated ? children : <Navigate to="/login" />;
};