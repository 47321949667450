import { useEffect, useState } from 'react';
import { Box, Button, CSSObject, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Theme as ThemeMui, Typography, styled, useTheme } from '@mui/material';
import { OpenInNew } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';
import MuiDrawer from '@mui/material/Drawer';
import { NavItem } from '../nav-item';
import { theme } from '../../constants/theme';
import { Upload as UploadIcon } from '../../icons/upload';
import { Cog as CogIcon } from '../../icons/cog';
import { Support as SupportIcon } from '../../icons/support';
import { ChartBar as ChartBarIcon } from '../../icons/chart-bar';
import { Logo } from '../../icons/logo';
import PsychologyIcon from '@mui/icons-material/Psychology';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import MapIcon from '@mui/icons-material/Map';
import RssFeedIcon from '@mui/icons-material/RssFeed';
import { getLicenceExpiry } from '../../actions/licencing';
import { getUserOrgName } from '../../actions/organization';
import { sideBarSize } from '../../constants/sizings';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { display } from 'html2canvas/dist/types/css/property-descriptors/display';

const topItems = [
    {
        href: 'incidents',
        icon: (<LocalFireDepartmentIcon fontSize="small" htmlColor="white" />),
        title: 'Incidents',
        bottomList: false,
    },
    {
        href: 'livestream',
        icon: (<RssFeedIcon fontSize="small" htmlColor="white" />),
        title: 'Livestream',
        bottomList: false
    },
    // {
    //     href: 'device-manager',
    //     icon: (<DevicesIcon fontSize="small" htmlColor={theme.secondary} />),
    //     title: 'Device Manager'
    // }
    // {
    //     href: 'data-manager',
    //     icon: (<UploadIcon fontSize='small' htmlColor="white" />),
    //     title: 'Data Manager',
    //     bottomList: false
    // }
];

const bottomItems = [
    {
        href: 'settings',
        icon: (<CogIcon fontSize="small" htmlColor="white" />),
        title: 'Settings',
        bottomList: true
    },
    {
        href: 'mailto:support@endurustechnologies.com',
        icon: (<SupportIcon fontSize="small" htmlColor="white" />),
        title: 'Support',
        bottomList: true
    },

];

const openedMixin = (theme: ThemeMui): CSSObject => ({
    width: sideBarSize,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: ThemeMui): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: sideBarSize,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export const PortalSidebar = (props: any) => {
    const [open, setOpen] = useState(true)
    const [licenceStatus, setLicenceStatus] = useState("")
    const [orgName, setOrgName] = useState("")

    const handleDrawerToggle = () => {
        if (open) {
            setOpen(false);
        } else {
            setOpen(true);
        }

    };

    useEffect(() => {
        getLicenceExpiry().then(
            (licenceStatus) => {
                setLicenceStatus(licenceStatus)
            }
        )
        getUserOrgName().then(
            (orgName) => {
                setOrgName(orgName)
            }
        )
    }, [])

    return (
        <Drawer
            anchor="left"
            open={open}
            PaperProps={{
                sx: {
                    backgroundColor: '#595959',
                    color: theme.textprimary,
                    overflow: "hidden",
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100vh',
                }
            }}
            variant="permanent"

        >
            <Box sx={{ alignItems: "center", display: "flex", paddingLeft: open ? "20px" : "0px", my: "25px", justifyContent: open ? 'space-between' : 'flex-start' }}>
                <Box sx={{ display: open ? "block" : "none" }}>
                    <NavLink to="/" key={"homepage"}>
                        <a>
                            <Logo
                                sx={{
                                    height: 42,
                                    width: 42,
                                }}
                            />
                        </a>
                    </NavLink>
                </Box>
                <IconButton
                    color="inherit"
                    aria-label="toggle drawer"
                    onClick={handleDrawerToggle}
                >
                    {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
            </Box>
            <List>
                {topItems.map((item) => (
                    <NavItem href={item.href} icon={item.icon} title={item.title} open={open} />
                ))}
            </List>
            <Box sx={{
                flexGrow: 1,
            }} />
            <List>
                {bottomItems.map((item) => (
                    <NavItem href={item.href} icon={item.icon} title={item.title} open={open} />
                ))}
            </List>
            <Box
                sx={{
                    alignItems: 'center',
                    backgroundColor: 'rgba(255, 255, 255, 0.04)',
                    cursor: 'pointer',
                    display: open ? 'flex' : 'none',
                    justifyContent: 'space-between',
                    px: 3,
                    py: '11px',
                    borderRadius: 1,
                    marginBottom: "30px"
                }}
            >
                <div>
                    <Typography
                        color="inherit"
                        variant="subtitle1"
                        sx={{ width: 0.7 * sideBarSize, overflow: "hidden", textOverflow: "ellipsis", maxHeight: "10vh", fontSize: "15px" }}
                    >
                        {orgName}
                    </Typography>
                    <Typography
                        color="neutral.400"
                        variant="body2"
                    >
                        Licenced until
                        {': '}
                        {licenceStatus}
                    </Typography>
                </div>
            </Box>
        </Drawer>
    );
};