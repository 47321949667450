const MINUTE = 60

export const time_per_mb =
    new Map()
        .set("dji_enterprise_thermal", 2.5 / MINUTE)
        .set("dji_advanced_enterprise_2_thermal", 2.5 / MINUTE)
        .set("dji_mavic_3_thermal", 2.5 / MINUTE)
        .set("dji_mavic_2_thermal", 2.5 / MINUTE)
        .set("dji_matrice_thermal", 2.5 / MINUTE)
        .set("dji_xt", 2.5 / MINUTE)
        .set("dji_xt2", 2.5 / MINUTE)
        .set("dji_zh20t", 2.5 / MINUTE)
        .set("l3_harris", 5 / MINUTE)
        // .set("DJI RGB", "dji_sdk_v4")
        .set("odf_unknown_payload", 5 / MINUTE)
        .set("shamrock_spectrograph", 2.5 / MINUTE)

export const overheads =
    new Map()
        .set("dji_advanced_enterprise_2_thermal", 1)
        .set("dji_mavic_3_thermal", 1)
        .set("dji_mavic_2_thermal", 1)
        .set("dji_matrice_thermal", 1)
        .set("dji_xt", 1)
        .set("dji_xt2", 1)
        .set("dji_zh20t", 1)
        .set("l3_harris", 3)
        // .set("DJI RGB", 1)
        .set("odf_unknown_payload", 3)
        .set("shamrock_spectrograph", 1)
