import { Box, Button, Card } from "@mui/material"
import { downloadMapGeojson, downloadMapShapefile } from "../../../../actions/maps/download-maps"
import { theme } from "../../../../constants/theme"
import { useEffect, useRef, useState } from "react"
import { getAnalysisMetadataFromStorageUsingRunID } from "../../../../actions/storage"
import { getAddressFromLatLon } from "../../../../actions/geocoding"
import { ExportPopover } from "./export-popover"
import { roundToTwo } from "../../../../actions/util"

export interface DataAnalyzerResultsCardProps {
    displayId: string
    displayItem: any | undefined
}

export const DataAnalyzerResultsCard = (props: DataAnalyzerResultsCardProps) => {

    const [displayMetadata, setDisplayMetadata] = useState<any | undefined>()
    const [address, setAddress] = useState<string>()
    const settingsRef = useRef(null)
    const [openExportPopover, setOpenExportPopover] = useState(false)

    const getResultMetadata = () => {
        if (props.displayId) {
            getAnalysisMetadataFromStorageUsingRunID({ urid: props.displayId }).then((res) => {
                console.log(res)
                setDisplayMetadata(res)
                getAddressFromLatLon(res?.coords[0], res?.coords[1]).then((address) => {
                    setAddress(address ?? "")
                })
            })
        }
    }

    useEffect(() => {
        getResultMetadata()
    }, [props.displayId])

    return (
        <Box sx={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)", width: "100%", display: "flex", flexDirection: "row", py: "5px", px: "15px", alignItems: "center" }}>
            <h4>{JSON.parse(props.displayItem?.output_path ?? '{ "name": "" }').name}</h4>
            <Box sx={{ flexGrow: 1, textAlign: "center" }}>
                <p>{address}</p>
            </Box>
            <Box sx={{ flexGrow: 1, textAlign: "center" }}>
                <span>{displayMetadata?.timestamp}</span>
            </Box>
            <Box sx={{ justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "row" }}>
                <Button
                    onClick={() => setOpenExportPopover(true)}
                    ref={settingsRef}
                    sx={{
                        width: "150px",
                        backgroundColor: theme.primary,
                        color: "white",
                        '&:hover': {
                            backgroundColor: theme.bigred
                        },
                        fontSize: theme.fontSizeMedium
                    }}
                >
                    Export as...
                </Button>
                <ExportPopover
                    anchorEl={settingsRef.current}
                    open={openExportPopover}
                    onClose={() => setOpenExportPopover(false)}
                    displayId={props.displayId}
                />
            </Box>
        </Box>
    )
}