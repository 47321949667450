import { useContext } from 'react';
import { Box, MenuItem, MenuList, Popover, Typography } from '@mui/material';
import { getAuth, signOut } from 'firebase/auth';
import { Link } from 'react-router-dom';

export interface AccountPopoverProps {
    anchorEl: any,
    onClose: Function,
    open: boolean
}

export const AccountPopover = (props: AccountPopoverProps) => {
    const { anchorEl, onClose, open, ...other } = props;
    const auth = getAuth()

    const handleSignOut = async () => {

        if (!auth.currentUser) {
            return;
        }

        try {
            signOut(getAuth())
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: 'left',
                vertical: 'bottom'
            }}
            onClose={() => onClose()}
            open={open}
            PaperProps={{
                sx: { width: '300px' }
            }}
            {...other}
        >
            <Box
                sx={{
                    py: 1.5,
                    px: 2
                }}
            >
                <Link to="/client-portal/settings">
                    <Typography variant="overline">
                        Account
                    </Typography>
                </Link>
            </Box>
            <MenuList
                disablePadding
                sx={{
                    '& > *': {
                        '&:first-of-type': {
                            borderTopColor: 'divider',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1px'
                        },
                        padding: '12px 16px'
                    }
                }}
            >
                <MenuItem onClick={handleSignOut}>
                    Sign out
                </MenuItem>
            </MenuList>
        </Popover>
    );
};