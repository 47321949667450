import { getFunctions, httpsCallable } from "firebase/functions";
import { app } from "../../firebase-config";

export const getPastLivestreams = async (logSetter: Function) => {
    //TODO:  we need to handle regions here? YES!
    const functions = getFunctions(app, "us-west1");
    const analysisAPI = httpsCallable(functions, 'getPastLivestreams');
    analysisAPI({ params: {} })
        .then((result: any) => {
            logSetter([...result.data[0]])
        })
        .catch((e) => console.log(e))
}
