import { useNavigate } from "react-router"

export const ClientHome = () => {
    const navigate = useNavigate()

    navigate("incidents");
    
    return (
        <></>
    )
}