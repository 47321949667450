import { Box, Container, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { StorageReference } from 'firebase/storage';
import { useEffect, useState } from 'react';
import { ItemTuple } from '../../../actions/storage';
import { DataManagerFiles } from '../../data-mananger/data-manager-files';
import { DataAnalyzerSetupSettings } from './data-analyzer-setup-settings';
import { SettingsPowerRounded } from '@mui/icons-material';

export interface DataAnalyzerSetupProps {
    handleClose: Function,
    navigator: Function,
    setLoiter: Function,
    open: boolean,
    onClose: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void,
    uiid?: string
}

export const DataAnalyzerSetup = (props: DataAnalyzerSetupProps) => {
    const [selectedFiles, setSelectedFiles] = useState<ItemTuple[]>([])

    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth={true} maxWidth={"md"}>
            <DialogTitle>New Analysis</DialogTitle>

            <DialogContent>
                <Container maxWidth={false} sx={{ display: "flex", flexDirection: "column" }}>
                    <Box sx={{minHeight: "50vh"}}>
                        <DataManagerFiles  {...{ isDataManager: false, filePasser: setSelectedFiles, isIncidentManager: true, lockFolder: `/${props.uiid}` }} />
                    </Box>
                    <Box>
                        <DataAnalyzerSetupSettings {...{ handleClose: props.handleClose, files: selectedFiles, navigator: props.navigator, setLoiter: props.setLoiter, uiid: props.uiid ?? undefined }} />
                    </Box>
                </Container>
            </DialogContent>
        </Dialog>

    )
}

