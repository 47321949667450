import { useEffect, useState } from "react"
import { IncidentCard, IncidentCardProps } from "./incident-card"
import { Box } from "@mui/material"
import { getIncidentsFromDatabase } from "../../../../../actions/incidents/get-incidents"


export interface IncidentOverviewProps {
    displayState: string,
    refresh: boolean
}

export const IncidentsOverview = (props: IncidentOverviewProps) => {

    const [incidentCardsData, setIncidentCardsData] = useState<Array<IncidentCardProps>>([])

    useEffect(() => {
        getIncidentsFromDatabase().then((incidents) => {
            console.log(incidents)
            if (incidents) {
                const arr = incidents.map((incident) => {
                    const data = incident.data()
                    return {
                        //TODO: is there a better way to link this to the type system
                        incidentName: data.name,
                        incidentType: data.incidentType,
                        incidentCreatedTimestamp: data.createdDate,
                        incidentLocation: data.incidentLocation,
                        incidentUIID: data.uiid
                    }
                })
                setIncidentCardsData(arr)
            }
        })
    }, [props.refresh])


    return (
        <Box sx={{ py: 3, display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {incidentCardsData.map((incidentCardData: IncidentCardProps) => {
                return <IncidentCard {...incidentCardData} />
            })}
        </Box>
    )
}