import { getAuth } from "firebase/auth"
import { collection, doc, getDocs, getFirestore, query, where } from "firebase/firestore"
import { app } from "../firebase-config"

export const isUserLicenced = async () => {

    const db = getFirestore(app)
    const uuid = getAuth().currentUser?.uid

    const licences = collection(db, "user_licences");
    const q = query(licences, where("uuid", "==", uuid))

    return await getDocs(q).then((res) => {
        if (res.docs.length <= 0) {
            return false
        }
        const ul = res.docs[0].data()

        if (ul.valid_until.seconds > (new Date().getTime() / 1000)) {
            return true
        }
        return false
    })
}

export const getLicenceTypes = async () => {

    const db = getFirestore(app)
    const uuid = getAuth().currentUser?.uid

    const licences = collection(db, "user_licences");
    const q = query(licences, where("uuid", "==", uuid))

    return await getDocs(q).then((res) => {
        if (res.docs.length <= 0) {
            return false
        }
        const ul = res.docs[0].data()

        return ul.licence_addons
    })
}

export const getLicenceExpiry = async () => {

    const db = getFirestore(app)
    const uuid = getAuth().currentUser?.uid

    const licences = collection(db, "user_licences");
    const q = query(licences, where("uuid", "==", uuid))

    return await getDocs(q).then((res) => {
        if (res.docs.length <= 0) {
            return "Unknown"
        }
        const ul = res.docs[0].data()
        const expiryDate = new Date(ul.valid_until.seconds * 1000 + ul.valid_until.nanoseconds / 1000000)
        const currentDate = new Date()

        if (expiryDate.getTime() > currentDate.getTime()) {
            return expiryDate.toLocaleDateString()
        } else {
            return "Expired"
        }
    })
}