import { useCallback, useState } from 'react'
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth'
import { Navigate, useNavigate } from 'react-router'
import { Helmet } from 'react-helmet'

import Navbar from '../../../components/navbar'
import Footer from '../../../components/footer'

import './index.css'
import { Link } from 'react-router-dom'
import { addDoc, collection, getFirestore } from 'firebase/firestore'
import { app } from '../../../firebase-config'
import { TextField } from '@mui/material'
import { theme } from '../../../constants/theme'

const SignUp = () => {
    const db = getFirestore(app)
    const navigate = useNavigate()

    const [email, setEmail] = useState<string | undefined>()
    const [org, setOrg] = useState<string | undefined>()
    const [registered, setRegistered] = useState(false)

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (email == undefined || org == undefined) {
            return
        }

        //TODO: temporarily just log the emails to see who's trying to get in
        try {
            console.log(email)
            addDoc(collection(db, "new_user_registrations"), {
                "email": email,
                "org": org,
                "timestamp": new Date().getTime()
            }).then(() => { setRegistered(true) })
        } catch (e) {
            console.log(e)
        }

        //TODO: this is the correct sign up code, but we want to be in stealth for now, so are doing manual additions
        // try {
        //     createUserWithEmailAndPassword(auth, email.value, password.value).then(() => {
        //         navigate("/client-portal")
        //     })
        // } catch (e) {
        //     //@ts-ignore
        //     alert(e.message)
        // }
    }

    return (
        <div className="signup-container">
            <Helmet>
                <title>Endurus</title>
                <meta property="og:title" content="Endurus Client Signup" />
            </Helmet>
            <div className="signup-signup">
                <Navbar {...{ PrimaryButtonText: "Client Login", PrimaryButtonPath: "/login" }}></Navbar>
                <div className="signup-container-1">
                    <p style={{ width: "50%", textOverflow: "unset" }}>Please register below, and we will be in touch with you within 24 hours to onboard you to the platform</p>
                    <br></br>
                    <form onSubmit={(e) => handleSubmit(e)} >
                        <TextField required id="outlined-basic" label="Email" variant="outlined" className="input-container-input input" sx={{ marginBottom: "5px" }} onChange={(e) => setEmail(e.target.value)} />
                        <br></br>
                        <TextField required id="outlined-basic" label="Organisation" variant="outlined" className="input-container-input input" sx={{ marginBottom: "5px" }} onChange={(e) => setOrg(e.target.value)} />
                        {/* <input name="email" placeholder="you@example.com" type="email" className="input-container-input input" style={{ marginBottom: "5px" }} /> */}
                        <br></br>
                        {!registered && <button type="submit" className="input-container-button button-primary button">Register</button>}
                        {registered && <button onClick={() => setRegistered(false)} style={{ backgroundColor: theme.complete }} className="input-container-button button-primary button">Success!</button>}
                    </form>
                    <br></br>
                    <br></br>
                    {/* <p>Terms of Service</p> */}
                    <p><a href='https://www.privacypolicies.com/live/c791fcfe-3e7d-42c0-9292-5f195f460881' target='_blank'>Privacy Policy</a></p>
                    <br></br>
                    <Link to="/login">
                        <span>Already have an account? <span style={{ fontWeight: "bold" }}>Log in here</span></span>
                    </Link>
                </div>
                <Footer ></Footer>
            </div>
        </div>
    )
}

export default SignUp