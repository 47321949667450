import { useEffect, useState } from "react";

import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Box,
    Card,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    Typography,
    Button,
    Badge,
    Avatar,
    Tooltip,
    CircularProgress
} from '@mui/material';
import MapIcon from '@mui/icons-material/Map'
import RefreshIcon from '@mui/icons-material/Refresh'
import InfoIcon from '@mui/icons-material/Info'
import { DataAnalyzerResultInfo } from "../../../../../../../../components/data-analyzer/data-analyzer-results/data-analyzer-results-info";
import { getDataAnalysisFromUrids, getStatusBadge } from "../../../../../../../../actions/data-analyzer";
import { theme } from "../../../../../../../../constants/theme";
import { styled } from "@mui/material/styles";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import { getIncidentFromDatabase } from "../../../../../../../../actions/incidents/get-incidents"
export interface DataAnalyzerRunsInterface {
    uiid: string | undefined,
    displayId: string | undefined,
    setDisplayId: Function,
    setDisplayItem: Function,
    displayItem: any | undefined,
    loiter?: any | undefined
}


//TODO: this is a very much improvised version for now. currently, it retrieves all analyses, then filters to incident.
// how do we handle gettings analyses that aren't on a user-by-user basis?
export const IncidentViewsMapDataAnalyzerRuns = (props: DataAnalyzerRunsInterface) => {

    const [logs, setLogs] = useState<JSON[]>([])
    const [uridInfo, setUridInfo] = useState<string | undefined>()

    const [openResultInfo, setOpenResultInfo] = useState(false)
    const [currentInfoItem, setCurrentInfoItem] = useState()
    const [currentInfoItemStatus, setCurrentInfoItemStatus] = useState()

    const setDisplayItemFromId = (id: string) => {
        props.setDisplayId(id)
        const item = logs.filter((log: any) => log.unique_run_id == id)
        props.setDisplayItem(item[0])
    }

    const shouldStillLoiter = () => {
        if (props.loiter) {
            const item = logs.filter((log: any) => log.unique_run_id == props.loiter.urid)
            if (item.length == 0) {
                return true
            }
        }
        return false

    }

    const updateRuns = () => {
        if (props.uiid) {
            getIncidentFromDatabase(props.uiid).then((incident) => {
                if (incident) {
                    const urids = incident.analyses
                    if (urids.length > 0) {
                        getDataAnalysisFromUrids(setLogs, urids)
                        if (props.displayItem === undefined) {
                            // setDisplayItemFromId(urids.slice(-1))
                        }
                    }
                }
            })
        }
    }

    useEffect(() => {
        updateRuns()
    }, [props.uiid])

    useEffect(() => {
        setInterval(() => { updateRuns() }, 10000)
    }, [])

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        [`&.${tableRowClasses.root}`]: {
            height: "5vh"
        },
    }))

    return (
        <Box sx={{ width: "100%", height: "100%", overflowY: "scroll", overflowX: "hidden" }} >
            <PerfectScrollbar>
                <Table>
                    <TableBody>
                        {
                            // waits for a run if it's known that it should be waiting for a run
                            //TODO: we should setup the backend to return the urid in the future and make this nicer
                            props.loiter && (shouldStillLoiter()) &&
                            (
                                <StyledTableRow key={"loiter"}>
                                    {/* <TableCell sx={{ width: "10px", pr: "0px" }}></TableCell> */}
                                    <TableCell><CircularProgress size="1.5rem" sx={{ color: theme.primary }} /></TableCell>
                                    <TableCell>{props.loiter.name}</TableCell>
                                    <TableCell><CircularProgress size="1.5rem" sx={{ color: theme.primary }} /></TableCell>
                                </StyledTableRow>
                            )

                        }
                        {logs.map((item: any, index) => {
                            let otherData: any;
                            try {
                                otherData = JSON.parse(item.output_path)
                            }
                            catch (e) {
                                return (<></>)
                            }
                            if (props.displayItem === undefined && index == 0) {
                                setDisplayItemFromId(item.unique_run_id)
                            }
                            const time = new Date(item.time)
                            return (
                                <StyledTableRow key={item.unique_run_id} sx={{ backgroundColor: item.unique_run_id == props.displayId ? "#9cbec6ff" : "inherit" }}>
                                    <TableCell sx={{ width: "10px", pr: "0px" }}>{getStatusBadge(item.status, time, item.unique_run_id)}</TableCell>
                                    <TableCell sx={{}}>{otherData.name}</TableCell>
                                    < TableCell sx={{}}>
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            {item.status == "Complete" && (<Tooltip title="View" >
                                                <Button sx={{ color: theme.secondary, margin: "0px!important", paddingRight: "2px!important", paddingLeft: "2px!important" }} startIcon={(<MapIcon fontSize="small" />)} onClick={() => { setDisplayItemFromId(item.unique_run_id) }} />
                                            </Tooltip>)}
                                            {item.status == "Complete" && (<Tooltip title="Info">
                                                <Button sx={{ color: theme.secondary, margin: "0px!important", paddingRight: "2px!important", paddingLeft: "2px!important" }} startIcon={(<InfoIcon fontSize="small" />)} onClick={() => { setCurrentInfoItem(item.output_path); setCurrentInfoItemStatus(item.status); setOpenResultInfo(true); setUridInfo(item.unique_run_id) }} />
                                            </Tooltip>)}
                                            {item.status == "Error" && (<Tooltip title="Info">
                                                <Button sx={{ color: theme.secondary, margin: "0px!important", paddingRight: "2px!important", paddingLeft: "2px!important" }} startIcon={(<InfoIcon fontSize="small" />)} onClick={() => { setCurrentInfoItem(item.output_path); setCurrentInfoItemStatus(item.status); setOpenResultInfo(true); setUridInfo(item.unique_run_id) }} />
                                            </Tooltip>)}
                                        </div>
                                    </TableCell>
                                </StyledTableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </PerfectScrollbar >
            <DataAnalyzerResultInfo {...{
                isOpen: openResultInfo,
                setIsOpen: setOpenResultInfo,
                currentInfoItem: currentInfoItem,
                status: currentInfoItemStatus,
                urid: props.displayId
            }} />
        </Box >
    )
}