import { Typography, Breadcrumbs, Link } from "@mui/material"
import { theme } from '../../../../constants/theme';

export interface BreadcrumbsInterface {
    folderPrefix: string,
    handleDirChange: Function
}
export const DataManagerBreadcrumbs = (props: BreadcrumbsInterface) => {
    const pathnames = props.folderPrefix.split('/').filter((x) => x)

    return (
        <Breadcrumbs aria-label='breadcrumb' sx={{ fontSize: theme.fontSizeMedium }}>
            <Typography color='inherit'></Typography>
            <Link underline="hover" color='inherit' onClick={() => props.handleDirChange('')}>Home</Link>

            {pathnames.map((value, index) => {
                const last = index === pathnames.length - 1
                const to = `${pathnames.slice(0, index + 1).join('/')}`

                return last ? (
                    <Typography color='text.primary' key={to}>{value}</Typography>
                ) : (
                    <Link underline="hover" color='inherit' key={to} onClick={() => props.handleDirChange(to)}>{value}</Link>
                )
            })}
        </Breadcrumbs>
    )
}